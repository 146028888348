export const GET_DELIVERY_CHARGE_INITATED = "GET_DELIVERY_CHARGE_INITATED";

export const GET_DELIVERY_CHARGE_SUCCEESS = "GET_DELIVERY_CHARGE_SUCCEESS";

export const GET_DELIVERY_CHARGE_FAILURE = "GET_DELIVERY_CHARGE_FAILURE";

export const CLEAR_GET_DELIVERY_CHARGE = "CLEAR_GET_DELIVERY_CHARGE";

export const GET_DUNZO_TRACKING_INTIATED = "GET_DUNZO_TRACKING_INTIATED";

export const GET_DUNZO_TRACKING_SUCCESS = "GET_DUNZO_TRACKING_SUCCESS";

export const GET_DUNZO_TRACKING_FAILED = "GET_DUNZO_TRACKING_FAILED";

export const GET_DUNZO_TRACKING_CLEAR = "GET_DUNZO_TRACKING_CLEAR";

export const DUNZO_RUNNER_ASSIGNED = "DUNZO_RUNNER_ASSIGNED";

export const DUNZO_ORDER_CREATED = "DUNZO_ORDER_CREATED";

export const GET_VALET_LOCATION = "GET_VALET_LOCATION"

export const VALET_LOCATION_SUCCESS = "VALET_LOCATION_SUCCESS"

export const VALET_LOCATION_FAILED = "VALET_LOCATION_FAILED"

export const CLEAR_VALET_LOCATION = "CLEAR_VALET_LOCATION"