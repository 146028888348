import { put, call, takeLatest, throttle } from "redux-saga/effects";
import {
  AuthActions,
  failedSignIn,
  successSignIn,
  failedVerifyOTP,
  failedVerifyMobile,
  successVerifyMobile,
  successVerifyOTP,
  verifyMobileOTPRequired,
  saveGuestUser,
  storeUser,
  resetPasswordRequest,
  resetPasswordFailed,
  resetPasswordSuccess,
  resetPasswordInitiateSuccess,
  getCountry,
} from "./AuthActions";
import {
  SIGNUP_REQUEST,
  SIGNIN_REQUEST,
  OTP_VERIFICATION_REQUEST,
  MOBILE_VERIFICATION_REQUEST,
  RESET_PASSWORD_REQUEST,
  GET_COUNTRY_CODE_SUCCESS,
  GET_COUNTRY_CODE_REQUEST,
} from "./AuthConstants";
import {
  signUp,
  signIn,
  verifyMobile,
  verifyOTP,
  resetPassword,
  getCountryCode,
} from "./AuthAPI";
import {
  SignUpForm,
  SignUpData,
  UserDetails,
  MetaResult,
  SignInForm,
  MessageResult,
  MobileVerificationDetails,
  MobileVerificationResult,
  OTPVerificationDetails,
  ResetPassword,
} from "./AuthModels";
import {
  ACCESS_TOKEN,
  GUEST_EMAIL,
  GUEST_MOBILE_NUMBER,
  GUEST_USER_NAME,
  MOBILE_NUMBER,
  REFRESH_TOKEN,
  USER_ID,
  USER_NAME,
} from "common/constants";
import { AxiosResponse } from "axios";
import { Store } from "common/configureStore";

// function* signUpSaga(action: AuthActions) {
//   try {
//     const response = yield call(signUp, action.payload as SignUpData);
//     // console.log(response);
//     if (response.status === 200) {
//       const result = response.data as MetaResult;
//       if (result.metaDataInfo.responseCode == "ERROR") {
//         yield put(
//           failedSignUp({ message: result.metaDataInfo.responseMessage })
//         );
//       } else if (result.metaDataInfo.responseCode == "SUCCESS") {
//         yield put(
//           successSignUp({ message: result.metaDataInfo.responseMessage })
//         );
//       }
//     }
//   } catch (err) {
//     yield put(failedSignUp({ message: "Please Try Again" }));
//   }
// }

function* signInSaga(action: AuthActions) {
  try {
    const response: AxiosResponse = yield call(
      signIn,
      action.payload as SignInForm
    );
    // console.log("RES:", response);
    if (response.data.userId) {
      const result = response.data as UserDetails;

      // Clear Guest Details
      localStorage.removeItem(GUEST_EMAIL);
      localStorage.removeItem(GUEST_MOBILE_NUMBER);
      localStorage.removeItem(GUEST_USER_NAME);

      // Storing Auth Details
      localStorage.setItem(USER_ID, result.id);
      result.fullName && localStorage.setItem(USER_NAME, result.fullName);
      result.accessToken &&
        localStorage.setItem(ACCESS_TOKEN, result.accessToken);
      result.refreshToken &&
        localStorage.setItem(REFRESH_TOKEN, result.refreshToken);
      result.userId && localStorage.setItem(MOBILE_NUMBER, result.userId);
      yield put(successSignIn(result));
    } else {
      yield put(failedSignIn({ message: "Invalid Username or Password" }));
    }
  } catch (err) {
    console.log("Catch", err);
    yield put(failedSignIn({ message: "Failed to SignIn, Please Try Again" }));
  }
}

function* verifyMobileSaganew(action: AuthActions) {
  const mobileRequestDetails = action.payload as MobileVerificationDetails;
  try {
    const isUserSignedIn = Store.getState().auth.signedIn;

    mobileRequestDetails.saveCustomerRelease = 1;
    const response: AxiosResponse = yield call(
      verifyMobile,
      mobileRequestDetails
    );
    if (response.status == 200) {
      const result = response.data as MetaResult;
      const guestUser = action.payload as MobileVerificationDetails;
      if (!isUserSignedIn) {
        // Store Guest User Details in Local Storage
        // Store UserID
        localStorage.setItem(USER_ID, result.metaDataInfo.responseMessage);
        yield put(
          storeUser({
            id: result.metaDataInfo.responseMessage,
          })
        );

        // Store Guest User
        // console.log("Storing Guest Info");

        localStorage.setItem(GUEST_USER_NAME, guestUser.fullName);
        localStorage.setItem(GUEST_MOBILE_NUMBER, guestUser.mobilePhone);
        if (guestUser.email.length > 0) {
          localStorage.setItem(GUEST_EMAIL, guestUser.email);
        }
        yield put(
          saveGuestUser({
            email: guestUser.email,
            fullName: guestUser.fullName,
            mobile: guestUser.mobilePhone,
            adultSize: 0,
            kidzSize: 0,
          })
        );
      }
      yield put(
        successVerifyMobile({ userID: result.metaDataInfo.responseMessage })
      );
    }
  } catch (err) {
    console.log({ err });
  }
}

function* verifyMobileSaga(action: AuthActions) {
  const mobileRequestDetails = action.payload as MobileVerificationDetails;
  const countryCode = mobileRequestDetails?.mobilePhone?.includes('+91') ? 'IND':'US';

  mobileRequestDetails.countryCode = countryCode;

  const isUserSignedIn = Store.getState().auth.signedIn;

  try {
    const response: AxiosResponse = yield call(verifyMobile, mobileRequestDetails );

    if (response.status === 200) {
      const result = response.data as MetaResult;
      if (result.metaDataInfo.responseCode == "SUCCESS" && (action.payload as OTPVerificationDetails).isGuestUser) {
        const guestUser = action.payload as MobileVerificationDetails;
        if (!isUserSignedIn) {
          // Store Guest User Details in Local Storage
          // Store UserID
          localStorage.setItem(USER_ID, result.metaDataInfo.responseMessage);
          yield put(
            storeUser({
              id: result.metaDataInfo.responseMessage,
            })
          );
  
          // Store Guest User
          // console.log("Storing Guest Info");
  
          localStorage.setItem(GUEST_USER_NAME, guestUser.fullName);
          localStorage.setItem(GUEST_MOBILE_NUMBER, guestUser.mobilePhone);
          if (guestUser.email.length > 0) {
            localStorage.setItem(GUEST_EMAIL, guestUser.email);
          }
          yield put(
            saveGuestUser({
              email: guestUser.email,
              fullName: guestUser.fullName,
              mobile: guestUser.mobilePhone,
              adultSize: 0,
              kidzSize: 0,
            })
          );
        }
        yield put(
          successVerifyMobile({ userID: result.metaDataInfo.responseMessage })
        );
      }else{
        yield put(verifyMobileOTPRequired({ message: result.metaDataInfo.responseMessage}));
      }
        
    }else{
      yield put(failedVerifyMobile({ message: response?.data?.message }));
    }
  } catch (err: any) {
      yield put(failedVerifyMobile({ message: "Please Try Again" }));
  }
}

function* verifyOTPSaga(action: AuthActions) {

  const mobileRequestDetails = action.payload as OTPVerificationDetails;
  const countryCode = mobileRequestDetails?.mobilePhone?.includes('+91') ? 'IND':'US';

  mobileRequestDetails.countryCode = countryCode;
  
  try {
    const response: AxiosResponse = yield call(verifyOTP ,
      mobileRequestDetails as OTPVerificationDetails
    ) as any;
    // console.log(response);
    if (response.status === 200) {
      const result = response.data as MetaResult;
      if (result.metaDataInfo.responseCode == "ERROR") {
        yield put(
          failedVerifyOTP({ message: result.metaDataInfo.responseMessage })
        );
      } else if (
        (action.payload as OTPVerificationDetails).isRegisterUser &&
        result.metaDataInfo.responseCode == "SUCCESS"
      ) {
        yield put(
          successVerifyOTP({ userID: result.metaDataInfo.responseMessage })
        );
      } else if ( (action.payload as OTPVerificationDetails).isGuestUser && result.metaDataInfo.responseCode == "SUCCESS") {
        // Store userId in Local Storage isGuestUser
        localStorage.setItem(USER_ID, result.metaDataInfo.responseMessage);
        localStorage.setItem(GUEST_USER_NAME,(action.payload as OTPVerificationDetails).fullName);
        localStorage.setItem(GUEST_MOBILE_NUMBER,(action.payload as OTPVerificationDetails).mobilePhone);
        localStorage.setItem(GUEST_EMAIL,(action.payload as OTPVerificationDetails).email);
        yield put(successVerifyOTP({ userID: result.metaDataInfo.responseMessage }));
      }else{
        yield put(successVerifyOTP({ userID: result.metaDataInfo.responseMessage }));
      }
    }else{
      yield put(failedVerifyOTP({ message: "Please Try Again" }));
    }
  } catch (err) {
    yield put(failedVerifyOTP({ message: "Please Try Again" }));
  }
}

function* resetPasswordSaga(action: AuthActions) {
  try {
    const requestData = action.payload as ResetPassword;

    const response: AxiosResponse = yield call(resetPassword, requestData);
    if (response.status == 200) {
      const result = response.data as MetaResult;
      if (result.metaDataInfo.responseCode == "SUCCESS") {
        if (requestData.sendOtp) {
          // Initiate Request
          yield put(resetPasswordInitiateSuccess());
        } else {
          // Update New Password
          yield put(resetPasswordSuccess());
        }
      } else {
        yield put(resetPasswordFailed(result.metaDataInfo.responseMessage));
      }
    }else{      
      yield put(resetPasswordFailed(response?.data?.message));
    }
  } catch (err) {
    yield put(resetPasswordFailed("Reset Password failed, Please try Again"));
  }
}

function* getCountryCodeSaga(action: AuthActions) {
  try {
    const response: AxiosResponse = yield call(getCountryCode);
    // console.log(response);
    if (response.status === 200) {
      yield put(getCountry(response.data));
    }
  } catch (err) {
    console.log("Error to get Country Code");
  }

}




export default function* authSaga() {
  // yield takeLatest(SIGNUP_REQUEST, signUpSaga);
  yield throttle(5000, SIGNIN_REQUEST, signInSaga);
  yield throttle(5000, OTP_VERIFICATION_REQUEST, verifyOTPSaga);
  yield throttle(5000, MOBILE_VERIFICATION_REQUEST, verifyMobileSaga);
  yield throttle(5000, RESET_PASSWORD_REQUEST, resetPasswordSaga);
  yield throttle(5000, GET_COUNTRY_CODE_REQUEST, getCountryCodeSaga);
}
