export const GET_ORDER_DETAIL_REQUEST = "GET_ORDER_DETAIL_REQUEST";
export const GET_KDS_ORDER_DETAIL_REQUEST = "GET_KDS_ORDER_DETAIL_REQUEST";
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";
export const CLEAR_GET_ORDER_DETAIL_REQUEST = "CLEAR_GET_ORDER_DETAIL_REQUEST";
export const GET_ORDER_DETAIL_FAILURE = "GET_ORDER_DETAIL_FAILURE";
export const RESET_GET_ORDER_DETAIL = "RESET_GET_ORDER_DETAIL";
export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";
export const GET_LATEST_ORDERS_REQUEST = "GET_LATEST_ORDERS_REQUEST";
export const GET_LATEST_ORDERS_SUCCESS = "GET_LATEST_ORDERS_SUCCESS";
export const GET_LATEST_ORDERS_ERROR = "GET_LATEST_ORDERS_ERROR";
export const CLEAR_ORDER_DETAILS = "CLEAR_ORDER_DETAILS";
export const CLEAR_CURRENT_ORDER_TYPE = "CLEAR_CURRENT_ORDER_TYPE";

// For Floating Cart
export const GET_ACTIVE_ORDERS_REQUEST = "GET_ACTIVE_ORDERS_REQUEST";
export const GET_ACTIVE_ORDERS_SUCCESS = "GET_ACTIVE_ORDERS_SUCCESS";
export const GET_ACTIVE_ORDERS_ERROR = "GET_ACTIVE_ORDERS_ERROR";

export const CLEAR_LATEST_ORDERS = "CLEAR_LATEST_ORDERS";
export const SET_CURRENT_ORDER_TYPE = "SET_CURRENT_ORDER_TYPE";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";
export const CLEAR_UPDATE_ORDER = "CLEAR_UPDATE_ORDER";

export const UPDATE_TIP_REQUEST = "UPDATE_TIP_REQUEST";
export const UPDATE_TIP_SUCCESS = "UPDATE_TIP_SUCCESS";
export const UPDATE_TIP_FAILURE = "UPDATE_TIP_FAILURE";
export const CLEAR_TIP_DETAIL = "CLEAR_TIP_DETAIL";

export const SAVE_ORDER_DETAILS = "SAVE_ORDER_DETAILS";
export const START_EMPTY_CART_ORDER = "START_EMPTY_CART_ORDER";