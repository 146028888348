import { AxiosResponse } from "axios";
import { put, call, takeLatest, throttle } from "redux-saga/effects";
import {
  CURRENT_ORDER_ID,
  ORDER_EXPIRE_TIME,
  ACTIVE_ORDERS,
} from "common/constants";

import {
  CartActions,
  failedPlaceOrder,
  successPlaceOrder,
} from "./cartActions";
import { placeOrder } from "./cartAPI";
import { PLACE_ORDER_REQUEST } from "./cartConstants";
import { Cart } from "./cartModels";
import { successGetOrderDetail } from "../order/orderActions";
import { OrderDetail } from "../order/orderModels";

function* placeOrderSaga(action: CartActions) {
  try {
    const response: AxiosResponse = yield call(
      placeOrder,
      action.payload as Cart
    );
    if (response.status === 200) {
      yield put(successPlaceOrder(response.data.orderId));
      yield put(successGetOrderDetail(response.data.order as OrderDetail));
      const activeOrder =
        localStorage.getItem("ACTIVE_ORDERS") &&
        JSON.parse(localStorage.getItem("ACTIVE_ORDERS") || "");
      if (!activeOrder) {
        let data = [
          {
            orderId: response.data.orderId,
            expiryTime: new Date().setHours(new Date().getHours() + 1),
          },
        ];
        localStorage.setItem(ACTIVE_ORDERS, JSON.stringify(data));
      } else {
        let data = [
          {
            orderId: response.data.orderId,
            expiryTime: new Date().setHours(new Date().getHours() + 1),
          },
          ...activeOrder,
        ];
        localStorage.setItem(ACTIVE_ORDERS, JSON.stringify(data));
      }
    } else {
      yield put(failedPlaceOrder(response?.data?.message ? response?.data?.message :'Please Retry'));
    }
  } catch (err) {
    yield put(failedPlaceOrder(err?.response?.data?.message));
  }
}

export default function* cartSaga() {
  yield throttle(5000, PLACE_ORDER_REQUEST, placeOrderSaga);
}
