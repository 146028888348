import API from "common/api";
import {
  AddFavRestaurants,
  LocationDetails,
  UserGivenDetail,
} from "./userModels";
var axios = require("axios");

export function getUserDetails() {
  const userID = localStorage.getItem("USER_ID");
  return (
    userID &&
    API({
      method: "get",
      url: `/customer/${userID}/profile`,
    })
  );
}

export function updateLocationDetails(data: LocationDetails) {
  const userID = localStorage.getItem("USER_ID");
  return API({
    method: "put",
    url: `/customer/${userID}/profile`,
    data: {
      customerLocation: data,
    },
  });
}
export function deleteLocation(data: LocationDetails) {
  const userID = localStorage.getItem("USER_ID");
  return API({
    method: "put",
    url: `/customer/${userID}/profile`,
    data: {
      customerLocation: data,
    },
  });
}

export function addFavRestaurant(data: AddFavRestaurants) {
  const userID = localStorage.getItem("USER_ID");
  return API({
    method: "put",
    url: `/customer/${userID}/profile`,
    data: {
      favoriteMerchantLocationRequest: data,
    },
  });
}
export function updateUserProfile() {
  return API({
    method: "put",
    url: "/customer/88e97221-feb5-4b35-a615-2f74a2132121/profile",
  });
}

export function updateEmail(email: string) {
  const userID = localStorage.getItem("USER_ID");

  return API({
    method: "put",
    url: `/customer/${userID}/profile`,
    data: { email: email },
  });
}

// For Promo Subscribed

export function promoConsent(promo: number) {
  const userID = localStorage.getItem("USER_ID");

  return API({
    method: "put",
    url: `/customer/${userID}/profile`,
    data: { isPromoSubscribed: promo },
  });
}
