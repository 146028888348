import { createStore, applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./rootReducer";
import rootSaga from "./rootSaga";
import { composeWithDevTools } from "redux-devtools-extension";
// // Redux Dev Tools
// declare global {
//   interface Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
//   }
// }
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Saga Middle Init
const sagaMiddleware = createSagaMiddleware();

const Store = createStore(
  rootReducer,
  // applyMiddleware(sagaMiddleware)
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
// Middleware: Redux Saga
sagaMiddleware.run(rootSaga);

export { Store  };
