import { typedAction } from "../actionTypes";
import {
  LOCATION_DELETE_FAILURE,
  LOCATION_DELETE_REQUEST,
  ADD_FAV_RESTAURANT,
  LOCATION_DELETE_SUCCESS,
  USERGIVEN_DETAIL_FAILURE,
  USERGIVEN_DETAIL_REQUEST,
  USERGIVEN_DETAIL_SUCCESS,
  CLEAR_USER_DATA,
  ADD_FAV_RESTAURANT_FAILURE,
  ADD_FAV_RESTAURANT_SUCCESS,
  EDIT_EMAIL_FAILURE,
  EDIT_EMAIL_REQUEST,
  EDIT_EMAIL_SUCCESS,
  SET_PROMO_CONSENT_FAILED,
  SET_PROMO_CONSENT_REQUEST,
  SET_PROMO_CONSENT_SUCCESS,
} from "./userConstants";
import {
  AddFavRestaurants,
  LocationDetails,
  UserGivenDetail,
} from "./userModels";

export function getUserGivenDetails() {
  return typedAction(USERGIVEN_DETAIL_REQUEST, "");
}
export function failedUserGivenDetails() {
  // Replace any with Network Data Fail Data format
  return typedAction(USERGIVEN_DETAIL_FAILURE, "");
}
export function successUserGivenDetails(details: UserGivenDetail) {
  return typedAction(USERGIVEN_DETAIL_SUCCESS, details);
}

export function failedDeleteLocation(message: Object) {
  // Replace any with Network Data Fail Data format
  return typedAction(LOCATION_DELETE_FAILURE, message);
}
export function successDeleteLocation(details: LocationDetails) {
  return typedAction(LOCATION_DELETE_SUCCESS, details);
}

export function updateDeleteLocation(data: LocationDetails) {
  return typedAction(LOCATION_DELETE_REQUEST, data);
}
export function addFavRestaurants(data: AddFavRestaurants) {
  return typedAction(ADD_FAV_RESTAURANT, data);
}

export function failedaddFavRestaurants(message: Object) {
  return typedAction(ADD_FAV_RESTAURANT_FAILURE, message);
}
export function successaddFavRestaurants(details: LocationDetails) {
  return typedAction(ADD_FAV_RESTAURANT_SUCCESS, details);
}

export function clearUserData() {
  return typedAction(CLEAR_USER_DATA, "");
}

export function editEmailRequest(email: string) {
  return typedAction(EDIT_EMAIL_REQUEST, email);
}

export function editEmailSuccess(data: any) {
  return typedAction(EDIT_EMAIL_SUCCESS, data);
}
export function editEmailFailure(error: string) {
  return typedAction(EDIT_EMAIL_FAILURE, error);
}

// For Promotional Maessages
export function setPromoConsentRequest(promo: number) {
  return typedAction(SET_PROMO_CONSENT_REQUEST, promo);
}

export function setPromoConsentSuccess() {
  return typedAction(SET_PROMO_CONSENT_SUCCESS, "");
}

export function setPromoConsentFailure(error: string) {
  return typedAction(SET_PROMO_CONSENT_FAILED, error);
}

export type userActions = ReturnType<
  | typeof getUserGivenDetails
  | typeof failedUserGivenDetails
  | typeof successUserGivenDetails
  | typeof updateDeleteLocation
  | typeof successDeleteLocation
  | typeof failedDeleteLocation
  | typeof failedaddFavRestaurants
  | typeof successaddFavRestaurants
  | typeof addFavRestaurants
  | typeof clearUserData
  | typeof editEmailFailure
  | typeof editEmailRequest
  | typeof editEmailSuccess
  | typeof setPromoConsentRequest
  | typeof setPromoConsentFailure
  | typeof setPromoConsentSuccess
>;
