import { getDecimalPlacedPriceString } from "common/utils/menu-helper-utils";
import { produce } from "immer";
import { stringify } from "querystring";
import { STORE_MENU_ITEM } from "../restaurant/restaurantContants";
import { CartActions } from "./cartActions";
import {
  ADD_CART_ITEM,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  REMOVE_CART_ITEM,
  // MODIFY_CART_ITEM,
  INCREMENT_CART_ITEM,
  DECREMENT_CART_ITEM,
  ADD_CUSTOMISE_INDIVIDUALLY,
  REMOVE_CUSTOMISE_INDIVIDUALLY,
  REMOVE_LAST_ITEM_INSTANCE,
  SAVE_ITEM_CUSTOMISATION,
  START_CUSTOMISE_ITEM,
  FINISH_ITEM_CUSTOMISATION,
  SAVE_ITEM_COMMENTS,
  SAVE_ORDER_DETAILS,
  CLEAR_PLACE_ORDER,
  SHOW_FLOATING_CART,
  HIDE_FLOATING_CART,
  REMOVE_CART_TIP,
  START_EMPTY_CART,
  SET_PICKUP_TIME,
  STORE_CART_RESTAURANT_DETAILS,
  SHOW_ITEM_MAX_COUNT_PROMPT,
  HIDE_ITEM_MAX_COUNT_PROMPT,
  LOAD_SAVED_INDIVIDUAL_CUSTOMISATION,
  CLEAR_PLACE_ORDER_SUCCESS,
  ADD_DELIVERY_ADDRESS,
  SET_CART_AUTH_PENDING,
  SET_GUEST_MOBILE_VERIFIED,
  SET_DELIVERY_ORDER_DISTANCE,
  SET_RUNNING_CART,
  CLEAR_RUNNING_CART,
  UPDATE_CART_ITEM,
  CLEAR_CUSTOMISE_ITEM,
  UPDATE_EVENT_ORDER,
  UPDATE_EVENT_ORDER_SLOT,
} from "./cartConstants";
import {
  Cart,
  CartItem,
  CartItemOption,
  CartLocationDetail,
  CartState,
} from "./cartModels";

export const EmptyLocationDetails: CartLocationDetail = {
  locationInfo: {
    center: {
      lat: 0,
      lng: 0,
    },
    text: "",
    tag: "",
    addressLine2: "",
    addressLine3: "",
  },
};

const EmptyCart: Cart = {
  locationId: "", // Res
  customerId: "",
  deviceId: "",
  staffId: "",
  fullName: "",
  email: "",
  phone: "",
  totalItems: "", // number
  comment: " ",
  orderTypeId: "",
  orderTotal: "", //number
  items: [],
  totals: [],
  deliveryTime: "",
  deliveryDate: "",
  etaDate: "",
  etaTime: "",
  pickUpDate: "",
  pickUpTime: "",
  locationDetails: EmptyLocationDetails,
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  deliveryDistance: 0,
  tableId: null,
};

const initialCartState: CartState = {
  // Restaurant Data
  cartRestaurantSlug: "",
  cartMenuType: "",
  isCartAuthPending: false,
  isGuestMobileVerified: false,
  cartURL: "",

  cartRestaurantDetails: null,
  cartMenuDefaultTax: null,
  cartRestaurantOrderTax: [],

  itemMaxCountPromptVisible: false,
  cartInitialized: false,
  cart: Object.assign({}, EmptyCart),
  orderTimeASAP: true,
  isEventDate:false,
  eventDate:'',
  eventSlot:'',
  tipValue: 0,
  tipType: "FLATFEE",
  isAppliedTip:false,

  // Customisations
  customisingItem: [],
  customisingIndividualItem: {
    enabled: false,
    itemId: "",
  },

  customisedIndividualItems: [],
  customisationSavedItems: [],
  customisedItemDetails: [],
  currentMenuItem: null,

  //Place Order
  placeOrderLoading: false,
  placeOrderMessage: "",
  placeOrderSuccess: false,
  placeOrderFailure: false,

  //Floating Cart Visibility
  showFloatingCart: false,
  orderId: "",
  merchantType: "",

  deliveryAddress: EmptyLocationDetails,
  runningCart: Object.assign({}, EmptyCart),

  errorMessage:''
};
const isMobile = window.innerWidth <= 500;

export default function cartReducer(
  state: CartState = initialCartState,
  action: CartActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case START_EMPTY_CART:
        if (!draft.cartInitialized) {
          if (
            action.payload.cartType === undefined ||
            action.payload.cartType != "RUNNING_CART"
          ) {
            draft.cart = Object.assign({}, EmptyCart);
          }
          draft.tipValue = 0;
          draft.tipType = "FLATFEE";
          draft.isAppliedTip=false;
          draft.customisingItem = [];
          draft.customisedIndividualItems = [];
          draft.customisationSavedItems = [];
          draft.cartRestaurantSlug = action.payload.restaurantSlug || "";
          draft.cartMenuType = action.payload.menuType || "";
          draft.merchantType = action.payload.merchantType || "";
          draft.cartInitialized = true;
          draft.orderTimeASAP = true;
          draft.isEventDate = false;
          // draft.eventDate = '';
          draft.isCartAuthPending = false;
          draft.cartURL = "";
          draft.isGuestMobileVerified = false;
        } else if (action.payload.clearCartItems) {
          draft.tipValue = 0;
          draft.tipType = "FLATFEE";
          draft.isAppliedTip=false;
          draft.cart = Object.assign({}, EmptyCart);
          draft.customisingItem = [];
          draft.customisedIndividualItems = [];
          draft.customisationSavedItems = [];
          draft.cartRestaurantSlug = action.payload?.restaurantSlug ? action.payload.restaurantSlug : "";
          draft.cartMenuType = action.payload?.menuType ? action.payload.menuType:  "";
          draft.merchantType = action.payload?.merchantType ? action.payload.merchantType : "";
          draft.orderTimeASAP = true;
          draft.isEventDate = false;
          // draft.eventDate = '';
        }
        break;
      case STORE_CART_RESTAURANT_DETAILS:
        if (draft.cartInitialized || !isMobile) {
          draft.cartRestaurantDetails = action.payload.restaurantDetails;
          draft.cartMenuDefaultTax = action.payload.restaurantDefaultTax;
          draft.cartRestaurantOrderTax = action.payload.restaurantOrderTax;
        }
        break;
      case ADD_CART_ITEM:
        if (action.payload.pseudo) {
          // Pseudo Cart for Cutomising Item
          draft.customisingItem = [
            ...draft.customisingItem,
            action.payload.item,
          ];
        } else {
          // Original CART
          draft.cart.items = [...draft.cart.items, action.payload.item];
        }
        break;

      case UPDATE_CART_ITEM:
        if (action.payload.pseudo) {
          draft.customisingItem = draft.customisingItem.map((item, index) => {
            if (item.itemId != action.payload.itemId) {
              return item;
            }

            if (action.payload.quantity) {
              item.quantity = String(action.payload.quantity);
              item.subTotal = action.payload.amount;
            } else if (Number(item.quantity) == action.payload.old_quantity) {
              item.subTotal = action.payload.amount;
            }
            item.is_discount_applied = action.payload.is_discount_applied;
            item.offer_data = action.payload.offer_data
              ? action.payload.offer_data
              : null;
              if(action.payload.stockQuantity){
                item.stockQuantity =action.payload.stockQuantity;
              }
              if(action.payload.alertQuantity){
                item.alertQuantity = action.payload.alertQuantity;
              }
            return item;
          });
        } else {
          if (action.payload.index == -1) {
            draft.cart.items = draft.cart.items.map((item, index) => {
              if (item.itemId != action.payload.itemId) {
                return item;
              }
              if (action.payload.quantity) {
                item.quantity = String(action.payload.quantity);
                item.subTotal = action.payload.amount;
              } else if (Number(item.quantity) == action.payload.old_quantity) {
                item.subTotal = action.payload.amount;
              }
              item.is_discount_applied = action.payload.is_discount_applied;
              item.offer_data = action.payload.offer_data
                ? action.payload.offer_data
                : null;
                if(action.payload.stockQuantity){
                  item.stockQuantity =action.payload.stockQuantity;
                }
                if(action.payload.alertQuantity){
                  item.alertQuantity = action.payload.alertQuantity;
                }
              return item;
            });
          } else {
            // Decrease quantity by 1 based on Item Index in cart
            draft.cart.items = draft.cart.items.map((item, index) => {
              if (
                item.itemId == action.payload.itemId &&
                index == action.payload.index
              ) {
                item.quantity = String(action.payload.quantity);
                item.subTotal = action.payload.amount;
              } else if (Number(item.quantity) == action.payload.old_quantity) {
                item.subTotal = action.payload.amount;
              }
              item.is_discount_applied = action.payload.is_discount_applied;
              item.offer_data = action.payload.offer_data
                ? action.payload.offer_data
                : null;
              return item;
            });
          }
        }
        break;
      case REMOVE_CART_ITEM:
        if (action.payload.pseudo) {
          if (action.payload.index == -1) {
            draft.customisingItem = draft.customisingItem.filter(
              (item) => item.itemId != action.payload.itemId
            );
          } else {
            draft.customisingItem.splice(action.payload.index, 1);
          }
        } else {
          if (action.payload.index == -1) {
            // Remove Item based in ItemId
            draft.cart.items = draft.cart.items.filter(
              (item) => item.itemId != action.payload.itemId
            );
          } else {
            draft.cart.items.splice(action.payload.index, 1);
          }
        }

        //Find no of ocucurence of an Item
        const itemCount = draft.cart.items.reduce(
          (accumulator: number, currentValue: CartItem) => {
            return currentValue.itemId == action.payload.itemId
              ? accumulator + 1
              : accumulator + 0;
          },
          0
        );
        if (!action.payload.pseudo) {
          // Remove from Customisation Saved Items and Individual Customised Items List also
          if (itemCount === 1) {
            draft.customisedIndividualItems =
              draft.customisedIndividualItems.filter(
                (itemId) => itemId != action.payload.itemId
              );
            draft.customisationSavedItems =
              draft.customisationSavedItems.filter(
                (itemId) => itemId != action.payload.itemId
              );
            draft.customisingItem = [];
          }
        }

        break;

      case REMOVE_LAST_ITEM_INSTANCE:
        draft.customisingItem.splice(
          draft.customisingItem.findIndex((item) => {
            return item.itemId == action.payload;
          }),
          1
        );
        break;
      case INCREMENT_CART_ITEM:
        if (action.payload.pseudo) {
          //console.log(action.payload.pseudo,"action.payload.pseudo")
          draft.customisingItem = draft.customisingItem.map((item, index) => {
            // console.log( draft.customisingItem,"VVVV")
            if (
              item.itemId == action.payload.itemId &&
              index == action.payload.index
            ) {
              item.quantity = parseInt(item.quantity) + 1 + "";
              item.subTotal = getDecimalPlacedPriceString(
                parseInt(item.quantity) * parseFloat(item.price)
              );
            }
            return item;
          });
        } else {
          // Increase quantity by 1
          if (action.payload.index == -1) {
            // Increment for Item by ID (Non Customised - single instance by ID)
            draft.cart.items = draft.cart.items.map((item) => {
              if (item.itemId == action.payload.itemId) {
                item.quantity = parseInt(item.quantity) + 1 + "";
                item.subTotal = getDecimalPlacedPriceString(
                  parseInt(item.quantity) * parseFloat(item.price)
                );
              }
              return item;
            });
          } else {
            // console.log("checking the incrementt")
            // Increment for Item by Item Instance Index in the cart
            draft.cart.items = draft.cart.items.map((item, index) => {
              if (
                item.itemId == action.payload.itemId &&
                index == action.payload.index
              ) {
                item.subTotal = getDecimalPlacedPriceString(
                  parseFloat(item.subTotal) +
                    parseFloat(item.subTotal) / parseInt(item.quantity)
                );
                // parseInt(item.quantity) * parseInt(item.price) + "";
                item.quantity = parseInt(item.quantity) + 1 + "";
              }
              return item;
            });
          }
        }
        break;
      case DECREMENT_CART_ITEM:
        // Decrease quantity in Pseudo Temporary Cart During Customisation
        if (action.payload.pseudo) {
          draft.customisingItem = draft.customisingItem.map((item, index) => {
            if (
              item.itemId == action.payload.itemId &&
              index == action.payload.index
            ) {
              item.quantity = parseInt(item.quantity) - 1 + "";
              item.subTotal = getDecimalPlacedPriceString(
                parseInt(item.quantity) * parseFloat(item.price)
              );
            }
            return item;
          });
        } else {
          // Decrease quantity by 1 based on ItemId
          if (action.payload.index == -1) {
            draft.cart.items = draft.cart.items.map((item) => {
              if (item.itemId == action.payload.itemId) {
                if (parseInt(item.quantity) > 0) {
                  item.quantity = parseInt(item.quantity) - 1 + "";
                  item.subTotal = getDecimalPlacedPriceString(
                    parseInt(item.quantity) * parseFloat(item.price)
                  );
                }
              }
              return item;
            });
          } else {
            // Decrease quantity by 1 based on Item Index in cart
            draft.cart.items = draft.cart.items.map((item, index) => {
              if (
                item.itemId == action.payload.itemId &&
                index == action.payload.index
              ) {
                if (parseInt(item.quantity) > 0) {
                  item.subTotal = getDecimalPlacedPriceString(
                    parseFloat(item.subTotal) -
                      parseFloat(item.subTotal) / parseInt(item.quantity)
                  );
                  item.quantity = parseInt(item.quantity) - 1 + "";
                }
              }
              return item;
            });
          }
        }
        // console.log(draft.cart.items);
        break;
      case ADD_CUSTOMISE_INDIVIDUALLY:
        // Add to Pseudo Only
        draft.customisingIndividualItem = {
          enabled: true,
          itemId: action.payload,
        };
        break;
      case REMOVE_CUSTOMISE_INDIVIDUALLY:
        // Remove from Pseudo Only
        draft.customisingIndividualItem = {
          enabled: false,
          itemId: action.payload,
        };
        break;
      case LOAD_SAVED_INDIVIDUAL_CUSTOMISATION:
        draft.customisingItem = draft.cart.items.filter(
          (item) => item.itemId == action.payload
        );
        break;
      case SAVE_ITEM_CUSTOMISATION:
        let instanceCount = 0;
        draft.customisingItem.map((item) => {
          if (item.itemId == action.payload.itemId) {
           // console.log(item,action.payload,instanceCount,"checkingg itemm")
            if (instanceCount == action.payload.instanceIndex) {
              let itemOptions: CartItemOption[] = [];
              action.payload.customisation.map((customisation) => {
                return itemOptions.push({
                  modifierOptionId: customisation.modifierOptionId,
                  optionName: customisation.optionName,
                  price: customisation.price,
                  quantity: customisation.quantity,
                  modifier: customisation.modifier,
                  typeId:customisation?.typeId,
                });
              });

              item.options = [...itemOptions];
              item.comment = action.payload.specialNote;
              item.price = action.payload.price;
              item.subTotal = action.payload.subTotal;
              item.offer_amount = +action.payload.offer_amount;
            }
            instanceCount = instanceCount + 1;
          }
        });
        // Add to Customisation Saved Items
        if (!draft.customisationSavedItems.includes(action.payload.itemId)) {
          draft.customisationSavedItems.push(action.payload.itemId);
        }
        break;
      case FINISH_ITEM_CUSTOMISATION:
        // Remove Old Customisation
        // draft.cart.items = draft.cart.items.filter(
        //   (item) => item.itemId != action.payload
        // );

        // Copy Customisation
        draft.customisingItem.forEach((rootItem) => {
          let itemExists = false;

          // Check if the item exists in the cart
          draft.cart.items = draft.cart.items.map((item) => {
            if (item.itemId === action.payload) {
              itemExists = true;
              return rootItem;
            }
            return item;
          });

          // If the item does not exist, push the new object
          if (!itemExists) {
            draft.cart.items.push(rootItem);
          }
        });

        // // Copy Customisation
        // draft.customisingItem.map((rootItem) => {
        //   draft.cart.items = draft.cart.items.filter(
        //     (item) => item.itemId == action.payload ? item == rootItem : item
        //   );
        //  // draft.cart.items.push(item);//
        // });

        // Sync Individual Customising Items
        if (
          draft.customisedIndividualItems.includes(
            draft.customisingIndividualItem.itemId
          ) &&
          !draft.customisingIndividualItem.enabled
        ) {
          // Remove if Not Enabled at Finish
          draft.customisedIndividualItems =
            draft.customisedIndividualItems.splice(
              draft.customisedIndividualItems.indexOf(
                draft.customisingIndividualItem.itemId
              ),
              1
            );
        } else if (
          !draft.customisedIndividualItems.includes(
            draft.customisingIndividualItem.itemId
          ) &&
          draft.customisingIndividualItem.enabled &&
          draft.customisingIndividualItem.itemId.length > 0
        ) {
          // Save if Enabled at Finish
          draft.customisedIndividualItems.push(
            draft.customisingIndividualItem.itemId
          );
        }

        // Remove Pseudo Customisations used in Customisation Page
        // draft.customisingItem = [];
        // draft.customisingIndividualItem = {
        //   enabled: false,
        //   itemId: "",
        // };
        break;
      case START_CUSTOMISE_ITEM:
        // Load Current Menu Details
        if (action.payload.length > 0) {
          let itemDetails = draft.customisedItemDetails.filter(
            (item) => item.itemId == action.payload[0].itemId
          );
          draft.currentMenuItem = itemDetails[0] || null;
        }

        // Load Previous Customisations
        draft.customisingItem = [...action.payload];

        // Load if Individually Customised
        if (
          action.payload.length > 0 &&
          draft.customisedIndividualItems.includes(action.payload[0].itemId)
        ) {
          draft.customisingIndividualItem = {
            enabled: true,
            itemId: action.payload[0].itemId,
          };
        }
        break;
      case CLEAR_CUSTOMISE_ITEM:
        draft.customisedIndividualItems= [];
        draft.customisationSavedItems= [];
        draft.customisedItemDetails= [];
        draft.currentMenuItem= null;
        break;
      case SAVE_ITEM_COMMENTS:
        draft.cart.items.map((item) => {
          if (item.itemId == action.payload.itemId) {
            item.comment = action.payload.comment;
          }
        });
        break;
      case REMOVE_CART_TIP:
        draft.tipValue = 0;
        draft.tipType = "FLATFEE";
        draft.isAppliedTip=false;
        break;
      case SAVE_ORDER_DETAILS:
        if(action.payload.tipValue == 0){
          draft.tipValue = action.payload.tipValue;
          draft.tipType = 'FLATFEE';
          draft.isAppliedTip=true;
        }
        
        if (action.payload.tipValue) {
          draft.tipValue = action.payload.tipValue;
          draft.isAppliedTip=true;
        }
        if (action.payload.tipType) {
          draft.tipType = action.payload.tipType;
        }
        if (action.payload.orderNote) {
          draft.cart.comment = action.payload.orderNote;
        }
        break;
      case PLACE_ORDER_REQUEST:
        draft.placeOrderLoading = true;
        draft.placeOrderSuccess = false;
        draft.placeOrderFailure = false;
        draft.cart.orderTotal = action.payload.orderTotal;
        break;
      case PLACE_ORDER_SUCCESS:
        // On Successful Create Order
        draft.placeOrderLoading = false;
        draft.placeOrderSuccess = true;
        // draft.cart.items = [];
        draft.orderId = action.payload.orderId;
        // Clear Pending Cart
        draft.isCartAuthPending = false;
        draft.cartURL = "";
        draft.isGuestMobileVerified = false;
        break;
      case PLACE_ORDER_FAILURE:
        draft.placeOrderLoading = false;
        draft.placeOrderSuccess = false;
        draft.placeOrderFailure = true;
        draft.errorMessage = action.payload;
        break;
      case CLEAR_PLACE_ORDER:
        // On Successful Payment
        draft.cartInitialized = false;

        // Clear Cart
        draft.cart = Object.assign({}, EmptyCart);
        draft.tipValue = 0;
        draft.tipType = "FLATFEE";
        draft.isAppliedTip=false;
        draft.customisingItem = [];
        draft.customisedIndividualItems = [];
        draft.customisationSavedItems = [];
        draft.orderTimeASAP = true;
        draft.customisedItemDetails = [];

        // Clear Pickup order
        draft.placeOrderMessage = "";
        draft.placeOrderLoading = false;
        draft.placeOrderSuccess = false;
        draft.placeOrderFailure = false;

        // clear Pending Cart
        draft.isCartAuthPending = false;
        draft.cartURL = "";
        draft.isGuestMobileVerified = false;
        break;
      case CLEAR_PLACE_ORDER_SUCCESS:
        draft.placeOrderSuccess = false;
        break;
      case SHOW_FLOATING_CART:
        draft.showFloatingCart = true;
        break;
      case HIDE_FLOATING_CART:
        draft.showFloatingCart = false;
        break;
      case SET_PICKUP_TIME:
        draft.cart.pickUpTime = action.payload.time;
        draft.cart.pickUpDate = action.payload.date;
        draft.cart.deliveryTime = action.payload.time;
        draft.cart.deliveryDate = action.payload.date;
        draft.orderTimeASAP = action.payload.isASAP;
        draft.isEventDate = action.payload.isEventDate;
       // draft.eventDate = action.payload?.eventDate;

        break;
      case SHOW_ITEM_MAX_COUNT_PROMPT:
        draft.itemMaxCountPromptVisible = true;
        break;
      case HIDE_ITEM_MAX_COUNT_PROMPT:
        draft.itemMaxCountPromptVisible = false;
        break;
      case STORE_MENU_ITEM:
        const tempMenuItem = [];
        tempMenuItem.push(action.payload);
        draft.customisedItemDetails = tempMenuItem
        break;
      case ADD_DELIVERY_ADDRESS:
        draft.cart.locationDetails = action.payload;
        draft.deliveryAddress = action.payload;
        break;
      case SET_CART_AUTH_PENDING:
        draft.isCartAuthPending = action.payload.status;
        draft.cartURL = action.payload.cartURL;
        break;
      case SET_GUEST_MOBILE_VERIFIED:
        draft.isGuestMobileVerified = action.payload;
        break;
      case SET_DELIVERY_ORDER_DISTANCE:
        draft.cart.deliveryDistance = action.payload;
        break;
      case SET_RUNNING_CART:
        draft.runningCart = action.payload as Cart;
        break;
      case CLEAR_RUNNING_CART:
        draft.runningCart = Object.assign({}, EmptyCart);
        break;
      case UPDATE_EVENT_ORDER:
        draft.isEventDate = action?.payload?.isEventOrder;
        draft.eventDate =  action?.payload?.eventDate;
        break;
      case UPDATE_EVENT_ORDER_SLOT:
        draft.eventSlot = action?.payload?.eventSlot;
        break; 
      default:
        break;
    }
  });
}
