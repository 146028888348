export const USERGIVEN_DETAIL_REQUEST = "USERGIVEN_DETAIL_REQUEST";
export const USERGIVEN_DETAIL_SUCCESS = "USERRGIVEN_DETAIL_SUCCESS";
export const USERGIVEN_DETAIL_FAILURE = "USERGIVEN_DETAIL_FAILURE";

export const LOCATION_DELETE_REQUEST = "LOCATION_DELETE_REQUEST";
export const LOCATION_DELETE_SUCCESS = "LOCATION_DELETE_SUCCESS";
export const LOCATION_DELETE_FAILURE = "LOCATION_DELETE_FAILURE";

export const ADD_FAV_RESTAURANT = "ADD_FAV_RESTAURANT";
export const ADD_FAV_RESTAURANT_SUCCESS = "ADD_FAV_RESTAURANT_SUCCESS";
export const ADD_FAV_RESTAURANT_FAILURE = "ADD_FAV_RESTAURANT_FAILURE";

export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

export const EDIT_EMAIL_REQUEST = "EDIT_EMAIL_REQUEST";
export const EDIT_EMAIL_SUCCESS = "EDIT_EMAIL_SUCCESS";
export const EDIT_EMAIL_FAILURE = "EDIT_EMAIL_FAILURE";

export const SET_PROMO_CONSENT_REQUEST = "SET_PROMO_CONSENT_REQUEST";
export const SET_PROMO_CONSENT_SUCCESS = "SET_PROMO_CONSENT_SUCCESS";
export const SET_PROMO_CONSENT_FAILED = "SET_PROMO_CONSENT_FAILED";
