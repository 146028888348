import { typedAction } from "../actionTypes";
import { OFFER_VALIDITY_REQUEST } from "../offers/offferConstants";
import {
  RAZOR_PAY_TRANSFER_ORDER,
  RAZOR_PAY_TRANSFER_ORDER_ERROR,
  RAZOR_PAY_TRANSFER_ORDER_FAILURE,
  RAZOR_PAY_TRANSFER_ORDER_SUCCESS,
  RAZOR_PAY_TRANSFER_ORDER_CLEAR,
  CLEAR_RAZOR_PAY_TRANSFER_ORDER_SUCCESS,
  FIRST_DATA_PAYMENT_REQUEST,
  FIRST_DATA_PAYMENT_SUCCESS,
  FIRST_DATA_PAYMENT_FAILURE,
  CLEAR_FIRST_DATA_PAYMENT,
  STRIPE_PAYMENT_REQUEST,
  STRIPE_PAYMENT_SUCCESS,
  STRIPE_PAYMENT_FAILURE,
  CLEAR_STRIPE_PAYMENT,
  STRIPE_PAYMENT_TOKEN_RETRIEVE_SUCCESS,
  STRIPE_CAPTURE_PAYMENT,
  OFFLINE_PAYMENT_FAILURE,
  OFFLINE_PAYMENT_REQUEST,
  OFFLINE_PAYMENT_SUCCESS,
  RESET_OFFLINE_PAYMENT_DATA,
  CLEAR_CNP_DATA_PAYMENT,
  CNP_DATA_PAYMENT_FAILURE,
  CNP_DATA_PAYMENT_REQUEST,
  CNP_DATA_PAYMENT_SUCCESS,
} from "./PaymentConstants";
import {
  CNPDataOrder,
  FirstDataOrder,
  RazorPayTransferOrder,
  RazorPayTransferredOrder,
  StripeCapturePaymentPayload,
  StripeInitiatePaymentPayload,
  offlineOrderData,
} from "./PaymentModels";

// store User
export function razorPayTransferOrder(details: RazorPayTransferOrder) {
  // console.log('Order pay');

  return typedAction(RAZOR_PAY_TRANSFER_ORDER, details);
}

export function succesRazorPayTransferOrder(details: RazorPayTransferredOrder) {
  return typedAction(RAZOR_PAY_TRANSFER_ORDER_SUCCESS, details);
}

export function failureRazorPayTransferOrder(details: any) {
  return typedAction(RAZOR_PAY_TRANSFER_ORDER_FAILURE, details);
}

export function errorRazorPayTransferOrder(details: any) {
  return typedAction(RAZOR_PAY_TRANSFER_ORDER_ERROR, details);
}

export function clearRazorPayTransferOrder() {
  return typedAction(RAZOR_PAY_TRANSFER_ORDER_CLEAR, "");
}

export function completeRazorPayTransferOrder() {
  return typedAction(CLEAR_RAZOR_PAY_TRANSFER_ORDER_SUCCESS, "");
}

export const firstDataPaymentRequest = (details: FirstDataOrder) => {
  return typedAction(FIRST_DATA_PAYMENT_REQUEST, details);
};

export const firstDataPaymentSuccess = () => {
  return typedAction(FIRST_DATA_PAYMENT_SUCCESS, "");
};

export const firstDataPaymentFailure = () => {
  return typedAction(FIRST_DATA_PAYMENT_FAILURE, "");
};

export const clearFirstDataPayment = () => {
  return typedAction(CLEAR_FIRST_DATA_PAYMENT, "");
};

export const stripePaymentRequest = (details: StripeInitiatePaymentPayload) => {
  return typedAction(STRIPE_PAYMENT_REQUEST, details);
};

export const stripePaymentSuccess = () => {
  return typedAction(STRIPE_PAYMENT_SUCCESS, "");
};

export const stripePaymentFailure = () => {
  return typedAction(STRIPE_PAYMENT_FAILURE, "");
};

export const clearStripePayment = () => {
  return typedAction(CLEAR_STRIPE_PAYMENT, "");
};

export const stripePaymentTokenRetrievealSuccess = (token: string) => {
  return typedAction(STRIPE_PAYMENT_TOKEN_RETRIEVE_SUCCESS, token);
};

export const stripeCapturePayment = (details: StripeCapturePaymentPayload) => {
  return typedAction(STRIPE_CAPTURE_PAYMENT, details);
};

// Offline Payment Actions
export const offlinePayment = (data: offlineOrderData) => {
  return typedAction(OFFLINE_PAYMENT_REQUEST, data);
};

export const offlinePaymentFailure = (data: string) => {
  return typedAction(OFFLINE_PAYMENT_FAILURE, data);
};

export const offlinePaymentSuccess = (data: any) => {
  return typedAction(OFFLINE_PAYMENT_SUCCESS, data);
};

export const resetOfflinePaymentData = () => {
  return typedAction(RESET_OFFLINE_PAYMENT_DATA, "");
};

export const cnpDataPaymentRequest = (details: CNPDataOrder) => {
  return typedAction(CNP_DATA_PAYMENT_REQUEST, details);
};

export const cnpDataPaymentSuccess = () => {
  return typedAction(CNP_DATA_PAYMENT_SUCCESS, "");
};

export const cnpDataPaymentFailure = (errorMessage:string) => {
  return typedAction(CNP_DATA_PAYMENT_FAILURE, errorMessage);
};

export const clearCNPDataPayment = () => {
  return typedAction(CLEAR_CNP_DATA_PAYMENT, "");
};

export type PaymentActions = ReturnType<
  | typeof razorPayTransferOrder
  | typeof succesRazorPayTransferOrder
  | typeof failureRazorPayTransferOrder
  | typeof errorRazorPayTransferOrder
  | typeof clearRazorPayTransferOrder
  | typeof completeRazorPayTransferOrder
  | typeof firstDataPaymentRequest
  | typeof firstDataPaymentSuccess
  | typeof firstDataPaymentFailure
  | typeof clearFirstDataPayment
  | typeof stripePaymentRequest
  | typeof stripePaymentSuccess
  | typeof stripePaymentFailure
  | typeof clearStripePayment
  | typeof stripePaymentTokenRetrievealSuccess
  | typeof stripeCapturePayment
  | typeof offlinePayment
  | typeof offlinePaymentSuccess
  | typeof offlinePaymentFailure
  | typeof resetOfflinePaymentData

  | typeof cnpDataPaymentRequest
  | typeof cnpDataPaymentSuccess
  | typeof cnpDataPaymentFailure
  | typeof clearCNPDataPayment
>;
