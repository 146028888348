import { produce } from 'immer';
import {
  CHECKIN_REQUEST,
  CHECKIN_SUCCESS,
  CHECKIN_FAILURE,
  WAITTIME_REQUEST,
  WAITTIME_SUCCESS,
  WAITTIME_FAILURE,
  CHECKIN_CANCEL_REQUEST,
  CHECKIN_UPDATE_REQUEST,
  CHECKIN_UPDATE_SUCCESS,
  CHECKIN_UPDATE_FAILURE,
  CHECKIN_DETAILS_REQUEST,
  CHECKIN_DETAILS_SUCCESS,
  CHECKIN_DETAILS_FAILURE,
  CHECKIN_CANCEL_SUCCESS,
  CHECKIN_CANCEL_FAILURE,
  CLEAR_CHECKIN_STATE, CHECKIN_STORE, SHOW_RESERVATION_STATUS, HIDE_RESERVATION_STATUS, FEEDBACK_SUBMITTED
} from "./checkInConstants";
import { CheckInActions } from './checkInActions';
import { CheckInState } from './checkInModels';


const initialCheckInState: CheckInState = {
  checkedIn: false,
  checkInRestaurantSlug: "",
  checkInBookingFormDetails: null,
  checkInConfirmationDetails: null,
  isFeedbackSubmitted: true,
  checkInFormState: null,
  checkInLoading: false,
  checkInMessage: "",
  waitTimes: [],
  waitTimeLoading: false,
  waitTimeMessage: "",
  checkInCancelled: false,
  checkInCancelLoading: false,
  checkInUpdated: false,
  checkInUpdateLoading: false,
  checkInBookedDetail: null,
  checkInReservationID: "",
  checkInDetailsLoading: false,
  floatingReservationVisible: true,
  CheckInSuccessResult :null,
  checkInFail:"",
};


export default function checkInReducer(state: CheckInState = initialCheckInState, action: CheckInActions) {

  return produce(state, draft => {
    switch (action.type) {
      case CLEAR_CHECKIN_STATE:

        draft.checkedIn = false;
        draft.checkInUpdated = false;
        draft.checkInCancelled = false;

        draft.checkInLoading = false;

        // draft.checkInBookedDetail = null;
        draft.checkInBookingFormDetails = null;
        draft.checkInConfirmationDetails = null;
        break;
      // CheckIn Reducers
      case CHECKIN_REQUEST:
        draft.checkedIn = false;
        draft.checkInRestaurantSlug = action.payload.restaurantSlug;
        draft.checkInLoading = true;
        draft.checkInConfirmationDetails = null;
        draft.checkInBookingFormDetails = action.payload;
        draft.checkInCancelled = false;
        draft.checkInUpdated = false;
        draft.checkInBookedDetail = null;
        draft.isFeedbackSubmitted = true;
        break;
      case CHECKIN_SUCCESS:
        draft.checkedIn = true;
        draft.CheckInSuccessResult = null;
        draft.checkInLoading = false;
        draft.checkInFormState = null;
        draft.checkInConfirmationDetails = action.payload;
        draft.isFeedbackSubmitted = action.payload.isFeedbackSubmitted;
        break;
      case CHECKIN_FAILURE:
        draft.checkedIn = false;
        draft.checkInLoading = false;
        draft.checkInConfirmationDetails = null;
        draft.checkInFail = action.payload.message
        break;
      case CHECKIN_STORE:
        draft.checkInFormState = action.payload;
        break;
      // WaitTime Reducers
      case WAITTIME_REQUEST:
        draft.waitTimeLoading = true;
        break;
      case WAITTIME_SUCCESS:
        draft.waitTimeLoading = false;
        draft.waitTimes = action.payload;
        break;
      case WAITTIME_FAILURE:
        draft.waitTimeLoading = false;
        draft.waitTimeMessage = action.payload.message;
        break;

      // Cancel CheckIN
      case CHECKIN_CANCEL_REQUEST:
        draft.checkInCancelLoading = true;
        draft.checkInCancelled = false;
        draft.checkedIn = false;
        break;
      case CHECKIN_CANCEL_SUCCESS:
        draft.checkInCancelLoading = false;
        draft.checkInCancelled = true;
        draft.checkInConfirmationDetails = null;
        draft.checkInBookingFormDetails = null;
        break;
      case CHECKIN_CANCEL_FAILURE:
        draft.checkInCancelLoading = false;
        draft.checkInCancelled = false;
        break;

      // Update CheckIn Reducers
      case CHECKIN_UPDATE_REQUEST:
        draft.checkInUpdateLoading = true;
        draft.checkInUpdated = false;
        break;
      case CHECKIN_UPDATE_SUCCESS:
        draft.checkInUpdateLoading = false;
        draft.checkInUpdated = true;
        break;
      case CHECKIN_UPDATE_FAILURE:
        draft.checkInUpdateLoading = false;
        draft.checkInUpdated = false;
        break;

      // CheckIn Details Reducers
      case CHECKIN_DETAILS_REQUEST:
        draft.checkInDetailsLoading = true;
        draft.checkInBookedDetail = null;
        draft.checkInReservationID = action.payload;
        break;
      case CHECKIN_DETAILS_SUCCESS:
        draft.checkInDetailsLoading = false;
        draft.checkInBookedDetail = action.payload;
        break;
      case CHECKIN_DETAILS_FAILURE:
        draft.checkInDetailsLoading = false;
        break;

      case SHOW_RESERVATION_STATUS:
        draft.floatingReservationVisible = true;
        break;
      case HIDE_RESERVATION_STATUS:
        draft.floatingReservationVisible = false;
        break;
      case FEEDBACK_SUBMITTED:
        draft.isFeedbackSubmitted = true;
        break;
      default:
        break;
    }
  });
}
