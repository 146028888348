import { AxiosResponse } from "axios";
import {Store} from "common/configureStore";
import { put, call, takeLatest,select } from "redux-saga/effects";
import { storeCartRestaurantDetails } from "../cart/cartActions";
import {
  restaurantActions,
  successRestaurantDetails,
  failedRestaurantDetails,
  successRestaurantMenuDetails,
  failedRestaurantMenuDetails,
  successFeedbackDetails,
  failedFeedbackDetails,
  successSubmitFeedbackDetails,
  failedSubmitFeedbackDetails,
  successRestaurants,
  failedRestaurants,
  successGetServingLocations,
  failedGetServingLocations,
  getRestaurantDetailsByLocationIdSuccess,
  getActiveOrderRestaurant,
  getActiveOrderRestaurantSuccess,
  getMasterHomePageDetailsFailed,
  getMasterHomePageDetailsSuccess,
  getRestaurantTableDetailsSuccess,
  getRestaurantTableDetailsFailed,
} from "./restaurantAction";
import {
  getFeedbackDetails,
  getmasterRestaurantHomePageDetails,
  getRestaurantDetails,
  getRestaurantDetailsbyLocationId,
  getRestaurantMenuDetails,
  getRestaurantMenuDetailsByEventDate,
  getRestaurants,
  getRestaurantTableDetails,
  submitFeedback,
} from "./restaurantAPI";
import {
  ACTIVE_ORDER_RESTAURANT_REQUEST,
  FEEDBACK_DETAIL_REQUEST,
  GET_MASTER_HOME_PAGE_DETAILS,
  GET_RESTAURANT_TABLE_DETAILS,
  RESTAURANTS_REQUEST,
  RESTAURANT_DETAIL_LOCATION_ID_REQUEST,
  RESTAURANT_DETAIL_REQUEST,
  RESTAURANT_MENU_DETAIL_REQUEST,
  RESTAURANT_MENU_DETAIL_REQUEST_BY_DATE,
  SERVING_RESTAURANTS_REQUEST,
  SUBMIT_FEEDBACK_DETAIL_REQUEST,
} from "./restaurantContants";
import {
  FeedbackQuestion,
  RestaurantMenuRequest,
  RestaurantMenuResponse,
  RestaurantsRequestParams,
  SubmitFeedbackDetail,
  RestaurantItemDetail,
  RestaurantDetailRequest,
  RestaurantDetailByLocationIdRequest,
} from "./restaurantModels";
import { getAvailableCatgorySubCategory } from "common/utils/menu-helper-utils";
import { getEventDate, getEventSlug } from "../selectors";

function* getLocationRestaurantsSaga(action: restaurantActions) {
  try {
    const requestDetails = action.payload as RestaurantsRequestParams;

    const response: AxiosResponse = yield call(getRestaurants, requestDetails);
    // console.log(response);
    if (response.status === 200) {
      yield put(successRestaurants(response.data as RestaurantItemDetail[]));
    } else if (response.status === 400) {
      yield put(failedRestaurants({ message: "Please Try Again" }));
    }
  } catch (err) {
    yield put(failedRestaurants({ message: "Please Try Again" }));
  }
}

function* getServingRestaurantsSaga(action: restaurantActions) {
  try {
    const requestDetails = action.payload as RestaurantsRequestParams;

    const response: AxiosResponse = yield call(getRestaurants, requestDetails);
    // console.log(response);
    if (response.status === 200) {
      yield put(
        successGetServingLocations(response.data as RestaurantItemDetail[])
      );
    } else if (response.status === 400) {
      yield put(failedGetServingLocations({ message: "Please Try Again" }));
    }
  } catch (err) {
    yield put(failedGetServingLocations({ message: "Please Try Again" }));
  }
}

function* getRestaurantDetailsSaga(action: restaurantActions) {
  let payload = action.payload as RestaurantDetailRequest;

  const eventSlug: string | null = yield select(getEventSlug); 
  const eventDate: boolean = yield select(getEventDate);     
  const isEventOrder = Boolean(eventDate) || Boolean(eventSlug);
  
  try {
    const response: AxiosResponse = yield call(
      getRestaurantDetails,
      payload.slug as string
    );
    // console.log(response);
    if (response.status === 200) {      
      yield put(successRestaurantDetails({detail:response.data,isEventOrder}));      
      if ( payload?.sagaCallBack != null &&typeof payload?.sagaCallBack === 'function') {
        payload.sagaCallBack(true,response?.data?.deliveryCutOffTimeExceeds,response?.data?.pickupCutOffTimeExceeds,response?.data);
      }
    } else{
      yield put(failedRestaurantDetails({ message: "Please Try Again",isEventOrder }));
      if ( payload?.sagaCallBack != null &&typeof payload?.sagaCallBack === 'function') {
        payload.sagaCallBack(false,1,1,'');
      }
    }
  } catch (err) {
    yield put(failedRestaurantDetails({ message: "Please Try Again",isEventOrder }));
    if ( payload?.sagaCallBack != null &&typeof payload?.sagaCallBack === 'function') {
      payload.sagaCallBack(false,1,1,'');
    }
  }
}

function* getRestaurantDetailsByLocationIdSaga(action: restaurantActions) {
  const eventSlug: string | null = yield select(getEventSlug); 
  const eventDate: boolean = yield select(getEventDate);     

  const isEventOrder = Boolean(eventDate) || Boolean(eventSlug);
  try {
    let payload = action.payload as RestaurantDetailByLocationIdRequest;
    const response: AxiosResponse = yield call(
      getRestaurantDetailsbyLocationId,
      payload.locationId
    );
    if (response.status === 200) {
      yield put(getRestaurantDetailsByLocationIdSuccess({details:response.data,isEventOrder}));
    } else if (response.status === 400) {
      yield put(failedRestaurantDetails({ message: "Please Try Again",isEventOrder }));
    }
  } catch (err) {
    yield put(failedRestaurantDetails({ message: "Please Try Again",isEventOrder }));
  }
}

function* getActiveOrderRestaurantSaga(action: restaurantActions) {
  try {
    let payload = action.payload as RestaurantDetailByLocationIdRequest;
    const response: AxiosResponse = yield call(
      getRestaurantDetailsbyLocationId,
      payload.locationId
    );
    if (response.status === 200) {
      yield put(getActiveOrderRestaurantSuccess(response.data));
    } else if (response.status === 400) {
      yield put(failedRestaurantDetails({ message: "Please Try Again" }));
    }
  } catch (err) {
    yield put(failedRestaurantDetails({ message: "Please Try Again" }));
  }
}

function* getRestaurantMenuDetailsByDateSaga(action: restaurantActions) {
  // console.log(action.type, action.payload);

  try {
    const response: AxiosResponse = yield call(getRestaurantMenuDetailsByEventDate,action.payload as RestaurantMenuRequest);
    if (response.status === 200) {
      // console.log(response.data);
      const responseData = response.data as RestaurantMenuResponse;
      const { categoryTypes, subCategoryTypes } = getAvailableCatgorySubCategory(responseData.menu);

      yield put(
        storeCartRestaurantDetails(
          Store.getState().restaurant.currentRestaurantDetail,
          responseData.orderTax || [],
          responseData.defaultItemTax
        )
      );
      const rData = {...responseData, categoryTypes, subCategoryTypes,eventSlot:responseData?.eventOrderSlot }
      yield put(successRestaurantMenuDetails(rData));
    } else {
      yield put(failedRestaurantMenuDetails({ message: "Please Try Again" }));
    }
  } catch (err) {
    yield put(failedRestaurantMenuDetails({ message: "Please Try Again" }));
  }
}

function* getRestaurantMenuDetailsSaga(action: restaurantActions) {
  // console.log(action.type, action.payload);

  try {
    const response: AxiosResponse = yield call(
      getRestaurantMenuDetails,
      action.payload as RestaurantMenuRequest
    );
    if (response.status === 200) {
      // console.log(response.data);
      const responseData = response.data as RestaurantMenuResponse;
      const { categoryTypes, subCategoryTypes } = getAvailableCatgorySubCategory(responseData.menu);

      yield put(
        storeCartRestaurantDetails(
          Store.getState().restaurant.currentRestaurantDetail,
          responseData.orderTax || [],
          responseData.defaultItemTax
        )
      );
      const rData = {...responseData, categoryTypes, subCategoryTypes }
      yield put(successRestaurantMenuDetails(rData));
    } else{
      yield put(failedRestaurantMenuDetails({ message: "Please Try Again" }));
    }
  } catch (err) {
    yield put(failedRestaurantMenuDetails({ message: "Please Try Again" }));
  }
}

function* getMasterHomePageDetailsSaga(action: restaurantActions) {
  try {
    const response: AxiosResponse = yield call(
      getmasterRestaurantHomePageDetails,
      action.payload as string
    );
    if (response.status === 200) {
      yield put(getMasterHomePageDetailsSuccess(response.data));
    } else if (response.status === 400) {
      // yield put(getMasterHomePageDetailsFailed({ message: "Please Try Again" }));
      console.log("Failed to get master homepage Details::");
    }
  } catch (err) {
    console.log("Failed to get master homepage Details::", err);
  }
}

function* getFeedbackDetailsSaga(action: restaurantActions) {
  try {
    const response: AxiosResponse = yield call(getFeedbackDetails);
    if (response.status === 200) {
      // console.log(response.data.question);
      yield put(
        successFeedbackDetails(response.data.question as FeedbackQuestion[])
      );
    } else {
      yield put(failedFeedbackDetails());
    }
  } catch (err) {
    yield put(failedFeedbackDetails());
  }
}

function* SubmitFeedbackDetailsSaga(action: restaurantActions) {
  try {
    const response: AxiosResponse = yield call(
      submitFeedback,
      action.payload as SubmitFeedbackDetail
    );
    if (response.status === 200) {
      console.log("Submitted Successfully");
      yield put(successSubmitFeedbackDetails());
    } else {
      yield put(failedSubmitFeedbackDetails());
    }
  } catch (err) {
    yield put(failedSubmitFeedbackDetails());
  }
}

function* getRestaurantTableDetailsSaga(action: restaurantActions) {
  let payload = action.payload as string;
  try {
    const response: AxiosResponse = yield call(
      getRestaurantTableDetails,
      payload as string
    );
    // console.log(response);
    if (response.status === 200) {
      yield put(getRestaurantTableDetailsSuccess(response.data));
    } else if (response.status === 400) {
      yield put(getRestaurantTableDetailsFailed());
    }
  } catch (err) {
    yield put(getRestaurantTableDetailsFailed());
  }
}

export default function* restaurantSaga() {
  yield takeLatest(RESTAURANT_DETAIL_REQUEST, getRestaurantDetailsSaga);
  yield takeLatest(
    RESTAURANT_DETAIL_LOCATION_ID_REQUEST,
    getRestaurantDetailsByLocationIdSaga
  );
  yield takeLatest(RESTAURANT_MENU_DETAIL_REQUEST_BY_DATE,getRestaurantMenuDetailsByDateSaga);
  yield takeLatest(
    RESTAURANT_MENU_DETAIL_REQUEST,
    getRestaurantMenuDetailsSaga
  );
  yield takeLatest(FEEDBACK_DETAIL_REQUEST, getFeedbackDetailsSaga);
  yield takeLatest(SUBMIT_FEEDBACK_DETAIL_REQUEST, SubmitFeedbackDetailsSaga);
  yield takeLatest(RESTAURANTS_REQUEST, getLocationRestaurantsSaga);
  yield takeLatest(SERVING_RESTAURANTS_REQUEST, getServingRestaurantsSaga);
  yield takeLatest(
    ACTIVE_ORDER_RESTAURANT_REQUEST,
    getActiveOrderRestaurantSaga
  );
  yield takeLatest(GET_MASTER_HOME_PAGE_DETAILS, getMasterHomePageDetailsSaga);
  yield takeLatest(GET_RESTAURANT_TABLE_DETAILS, getRestaurantTableDetailsSaga);
}
