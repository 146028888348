import { produce } from "immer";
import { PaymentActions } from "./PaymentActions";
import {
  CLEAR_FIRST_DATA_PAYMENT,
  CLEAR_RAZOR_PAY_TRANSFER_ORDER_SUCCESS,
  FIRST_DATA_PAYMENT_FAILURE,
  FIRST_DATA_PAYMENT_REQUEST,
  FIRST_DATA_PAYMENT_SUCCESS,
  RAZOR_PAY_TRANSFER_ORDER,
  RAZOR_PAY_TRANSFER_ORDER_CLEAR,
  RAZOR_PAY_TRANSFER_ORDER_ERROR,
  RAZOR_PAY_TRANSFER_ORDER_FAILURE,
  RAZOR_PAY_TRANSFER_ORDER_SUCCESS,
  STRIPE_PAYMENT_REQUEST,
  STRIPE_PAYMENT_SUCCESS,
  STRIPE_PAYMENT_FAILURE,
  CLEAR_STRIPE_PAYMENT,
  STRIPE_PAYMENT_TOKEN_RETRIEVE_SUCCESS,
  OFFLINE_PAYMENT_SUCCESS,
  OFFLINE_PAYMENT_REQUEST,
  OFFLINE_PAYMENT_FAILURE,
  RESET_OFFLINE_PAYMENT_DATA,
  CLEAR_CNP_DATA_PAYMENT,
  CNP_DATA_PAYMENT_FAILURE,
  CNP_DATA_PAYMENT_REQUEST,
  CNP_DATA_PAYMENT_SUCCESS,
} from "./PaymentConstants";
import { PaymentState } from "./PaymentModels";

const initialCheckInState: PaymentState = {
  // RazorPay
  razorPayTransferOrderLoading: false,
  razorPayTransferOrderFailure: false,
  razorPayTransferOrderMessage: "",
  razorPayTransferOrderSuccess: false,

  // First Data
  firstDataPaymentLoading: false,
  firstDataPaymentSuccess: false,
  firstDataPaymentFailure: false,
  razorPayTransferredOrderDetails: null,

  // Stripe
  stripePaymentLoading: false,
  stripePaymentSuccess: false,
  stripePaymentFailure: false,
  stripeTokenRetrieveSuccess: false,
  stripePaymentToken: "",

  // Offline Payment
  offlinePaymentRequestLoading: false,
  offlinePaymentSuccess: false,
  offlinePaymentFailure: false,
  offlinePaymentMessage: "",

  cnpErrorMessage:''
};

export default function paymentReducer(
  state: PaymentState = initialCheckInState,
  action: PaymentActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      // Payment Reducers
      case RAZOR_PAY_TRANSFER_ORDER:
        draft.razorPayTransferOrderLoading = true;
        draft.razorPayTransferOrderFailure = false;
        draft.razorPayTransferOrderMessage = "";
        draft.razorPayTransferOrderSuccess = false;
        break;
      case RAZOR_PAY_TRANSFER_ORDER_SUCCESS:
        draft.razorPayTransferOrderLoading = false;
        draft.razorPayTransferOrderSuccess = true;
        draft.razorPayTransferredOrderDetails = action.payload;
        break;
      case RAZOR_PAY_TRANSFER_ORDER_FAILURE:
        draft.razorPayTransferOrderLoading = false;
        draft.razorPayTransferOrderFailure = true;
        draft.razorPayTransferOrderMessage = "";
        break;
      case RAZOR_PAY_TRANSFER_ORDER_ERROR:
        draft.razorPayTransferOrderLoading = false;
        draft.razorPayTransferOrderFailure = true;
        draft.razorPayTransferOrderMessage = "";
        break;
      case RAZOR_PAY_TRANSFER_ORDER_CLEAR:
        draft.razorPayTransferOrderLoading = false;
        draft.razorPayTransferOrderFailure = false;
        draft.razorPayTransferOrderMessage = "";
        draft.razorPayTransferOrderSuccess = false;
        draft.razorPayTransferredOrderDetails = null;
        break;
      case CLEAR_RAZOR_PAY_TRANSFER_ORDER_SUCCESS:
        draft.razorPayTransferOrderSuccess = false;
        break;
      case FIRST_DATA_PAYMENT_REQUEST:
        draft.firstDataPaymentLoading = true;
        draft.firstDataPaymentFailure = false;
        //draft.cnpErrorMessage='';
        break;
      case FIRST_DATA_PAYMENT_SUCCESS:
        draft.firstDataPaymentLoading = false;
        draft.firstDataPaymentSuccess = true;
        break;
      case FIRST_DATA_PAYMENT_FAILURE:
        draft.firstDataPaymentLoading = false;
        draft.firstDataPaymentSuccess = false;
        draft.firstDataPaymentFailure = true;
        break;
      case CLEAR_FIRST_DATA_PAYMENT:
        draft.firstDataPaymentLoading = false;
        draft.firstDataPaymentSuccess = false;
        draft.firstDataPaymentFailure = false;
        //draft.cnpErrorMessage='';
        break;
      case STRIPE_PAYMENT_REQUEST:
        draft.stripePaymentLoading = true;
        draft.stripePaymentFailure = false;
        draft.stripeTokenRetrieveSuccess = false;
        break;
      case STRIPE_PAYMENT_TOKEN_RETRIEVE_SUCCESS:
        draft.stripeTokenRetrieveSuccess = true;
        draft.stripePaymentToken = action.payload;
        break;
      case STRIPE_PAYMENT_SUCCESS:
        draft.stripePaymentLoading = false;
        draft.stripePaymentSuccess = true;
        break;
      case STRIPE_PAYMENT_FAILURE:
        draft.stripePaymentLoading = false;
        draft.stripePaymentSuccess = false;
        draft.stripePaymentFailure = true;
        draft.stripeTokenRetrieveSuccess = false;
        draft.stripePaymentToken = "";
        break;
      case CLEAR_STRIPE_PAYMENT:
        draft.stripePaymentLoading = false;
        draft.stripePaymentSuccess = false;
        draft.stripePaymentFailure = false;
        draft.stripeTokenRetrieveSuccess = false;
        draft.stripePaymentToken = "";
        break;
      case OFFLINE_PAYMENT_REQUEST:
        draft.offlinePaymentRequestLoading = true;
        draft.offlinePaymentFailure = false;
        draft.offlinePaymentSuccess = false;
        break;
      case OFFLINE_PAYMENT_SUCCESS:
        draft.offlinePaymentRequestLoading = false;
        draft.offlinePaymentFailure = false;
        draft.offlinePaymentSuccess = true;
        draft.offlinePaymentMessage = action.payload;
        break;
      case OFFLINE_PAYMENT_FAILURE:
        draft.offlinePaymentRequestLoading = false;
        draft.offlinePaymentFailure = true;
        draft.offlinePaymentSuccess = false;
        draft.offlinePaymentMessage = action.payload;
        break;
      case RESET_OFFLINE_PAYMENT_DATA:
        draft.offlinePaymentRequestLoading = false;
        draft.offlinePaymentFailure = false;
        draft.offlinePaymentSuccess = false;
        break;

      case CNP_DATA_PAYMENT_REQUEST:
        draft.firstDataPaymentLoading = true;
        draft.firstDataPaymentFailure = false;
        draft.cnpErrorMessage='';
        break;
      case CNP_DATA_PAYMENT_SUCCESS:
        draft.firstDataPaymentLoading = false;
        draft.firstDataPaymentSuccess = true;
        break;
      case CNP_DATA_PAYMENT_FAILURE:
        draft.firstDataPaymentLoading = false;
        draft.firstDataPaymentSuccess = false;
        draft.firstDataPaymentFailure = true;
        draft.cnpErrorMessage=action.payload;
        break;
      case CLEAR_CNP_DATA_PAYMENT:
        draft.firstDataPaymentLoading = false;
        draft.firstDataPaymentSuccess = false;
        draft.firstDataPaymentFailure = false;
       // draft.cnpErrorMessage='';
        break;  
      default:
        break;
    }
  });
}
