import React, { Fragment, useEffect } from "react";
import { startEmptyCart } from "common/features/cart/cartActions";
import { RootState } from "common/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { startEmptyCartOrder } from "common/features/order/orderActions";

export const CartWatcherService = () => {
  const dispatch = useDispatch();
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.cart.items.length
  );
  const cartInit = useSelector(
    (state: RootState) => state.cart.cartInitialized
  );
  useEffect(() => {
    if (
      cartItemCount == 0 &&
      cartInit &&
      !window.location.pathname.includes("table")
    ) {
      // dispatch(
      //   startEmptyCart({
      //     clearCartItems: true,
      //   })
     // );
      dispatch(startEmptyCartOrder())

    }
  }, [cartItemCount]);
  return <Fragment></Fragment>;
};
