import React, { useEffect } from "react";

export const useNotificationService = (): [(message: string) => void] => {
  const ORDER_CHANNEL = "ORDER";

  const isPushNotificationSupported = () => {
    return "serviceWorker" in navigator && "PushManager" in window;
  };

  async function askUserPermission() {
    return await Notification.requestPermission();
  }

  useEffect(() => {
    // Check Browser Support
    // const isSupported = isPushNotificationSupported();
    // Request Permission
    // if (isSupported) {
    //   askUserPermission().then((result) => {
    //     console.log(result);
    //   });
    // }
  }, []);

  const showOrderNotification = (message: string) => {};

  return [showOrderNotification];
};
