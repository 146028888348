import { typedAction } from "../actionTypes";
import { MetaDataInfo, tinyURLRequest, MetaResult } from "./tinyURLModels";
import {
  GET_TINYURL_ERROR,
  GET_TINYURL_REQUEST,
  GET_TINYURL_SUCCESS,
  CLEAR_USE_TINYURL,
} from "./tinyURLConstants";

export function requestActualURL(details: tinyURLRequest) {
  return typedAction(GET_TINYURL_REQUEST, details);
}
export function successgetActualURL(metaData: MetaResult) {
  return typedAction(GET_TINYURL_SUCCESS, metaData);
}
export function failgetActualURL() {
  return typedAction(GET_TINYURL_ERROR, "");
}
export type tinyURLActions = ReturnType<
  typeof requestActualURL | typeof successgetActualURL | typeof failgetActualURL
>;
