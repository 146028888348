import {
  CREATE_SUPPORT_FAILURE,
  CREATE_SUPPORT_REQUEST,
  CREATE_SUPPORT_SUCCESS,
} from "./SupportConstants";
import { typedAction } from "../actionTypes";
import { supportRequestData, supportResponseData } from "./SupportModels";
import { type } from "os";

export function createSupportRequest(data: supportRequestData) {
  return typedAction(CREATE_SUPPORT_REQUEST, data);
}

export function createSupportSuccess(data: supportResponseData) {
  return typedAction(CREATE_SUPPORT_SUCCESS, data);
}

export function createSupportFailure() {
  return typedAction(CREATE_SUPPORT_FAILURE, "");
}

export type SupportActions = ReturnType<
  | typeof createSupportRequest
  | typeof createSupportSuccess
  | typeof createSupportFailure
>;
