import { put, call, throttle } from "redux-saga/effects";
import {
  makeBooking,
  getWaitTimes,
  cancelBooking,
  updateBooking,
  getBooking,
} from "./checkInAPI";
import {
  CHECKIN_REQUEST,
  WAITTIME_REQUEST,
  CHECKIN_UPDATE_REQUEST,
  CHECKIN_CANCEL_REQUEST,
  CHECKIN_DETAILS_REQUEST,
} from "./checkInConstants";
import {
  successBooking,
  failedBooking,
  CheckInActions,
  successWaitTimes,
  failedWaitTimes,
  successCancelBooking,
  failedCancelBooking,
  successEditBooking,
  failedEditbooking,
  successGetBooking,
  failedGetBooking,
} from "./checkInActions";
import {
  CheckInBookingForm,
  WaitTimeRestaurantDetail,
  CancelCheckInDetails,
  CheckInBookingDetails,
  CheckInSuccessResult,
} from "./checkInModels";
import { CURRENT_CHECKIN_RESERVATION_ID, EXPIRE_TIME } from "common/constants";
import { AxiosResponse } from "axios";

const getCheckInDetails = (data: CheckInBookingForm) => {
  const CheckInMetaData = {
    deviceId: "",
    channelName: "ONLINE",
    seatTogether: "1",
  };

  let checkInDetails = Object.assign({}, data as any);
  checkInDetails["partySize"] =
    parseInt(checkInDetails["adultSize"]) +
    (parseInt(checkInDetails["kidSize"]) || 0);
  delete checkInDetails["adultSize"];
  delete checkInDetails["restaurantSlug"];
  checkInDetails = Object.assign(
    {},
    checkInDetails,
    CheckInMetaData
  ) as CheckInBookingDetails;
  // console.log('Booking:', checkInDetails);
  return checkInDetails;
};

function* makeBookingSaga(action: CheckInActions) {
  try {
    const checkInDetails = getCheckInDetails(
      action.payload as CheckInBookingForm
    );

    const response: AxiosResponse = yield call(makeBooking, checkInDetails);

    // console.log(response);
    if (response.status === 200) {
      const responseData = response.data as CheckInSuccessResult;
      //Store Latest CheckIn ReservationID
      localStorage.setItem(
        EXPIRE_TIME,
        JSON.stringify(new Date().setMinutes(new Date().getMinutes() + 5))
      );
      localStorage.setItem(CURRENT_CHECKIN_RESERVATION_ID, responseData.id);
      yield put(successBooking(responseData));
    }
  } catch (err: any) {
    yield put(failedBooking({ message: err?.response.data.message }));
  }
}

function* getWaitTimeSaga(action: CheckInActions) {
  try {
    const response: AxiosResponse = yield call(
      getWaitTimes,
      action.payload as WaitTimeRestaurantDetail
    );
    // console.log(response);
    if (response.status === 200) {
      yield put(successWaitTimes(response.data));
    }
  } catch (err) {
    yield put(failedWaitTimes({ message: "WaitTimes couldn't be loaded" }));
  }
}

function* cancelBookingSaga(action: CheckInActions) {
  try {
    const response: AxiosResponse = yield call(
      cancelBooking,
      action.payload as CancelCheckInDetails
    );
    // console.log(response);
    if (response.status === 200) {
      yield put(successCancelBooking());
    }
  } catch (err) {
    yield put(failedCancelBooking({ message: "Please Try Again" }));
  }
}

function* updateBookingSaga(action: CheckInActions) {
  try {
    const checkInDetails = getCheckInDetails(
      action.payload as CheckInBookingForm
    );
    const response: AxiosResponse = yield call(updateBooking, checkInDetails);
    // console.log(response);
    if (response.status === 200) {
      yield put(successEditBooking(response.data));
    }
  } catch (err) {
    yield put(failedEditbooking({ message: "Please Try Again" }));
  }
}

function* getBookingSaga(action: CheckInActions) {
  try {
    const response: AxiosResponse = yield call(
      getBooking,
      action.payload as string
    );
    // console.log(response);
    if (response.status === 200) {
      yield put(successGetBooking(response.data[0]));
    }
  } catch (err) {
    yield put(failedGetBooking({ message: "Please Try Again" }));
  }
}

export default function* checkInSaga() {
  yield throttle(5000, CHECKIN_REQUEST, makeBookingSaga);
  yield throttle(5000, WAITTIME_REQUEST, getWaitTimeSaga);
  yield throttle(5000, CHECKIN_CANCEL_REQUEST, cancelBookingSaga);
  yield throttle(5000, CHECKIN_UPDATE_REQUEST, updateBookingSaga);
  yield throttle(5000, CHECKIN_DETAILS_REQUEST, getBookingSaga);
}
