import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  ReactElement,
} from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import { stagingConfig, productionConfig } from "../firebaseConfig";

export const firebaseAppContext = createContext<firebase.app.App | null>(null);

interface FirebaseAppProviderProps {
  children: ReactNode;
  fallback?: ReactElement;
}

export default function FirebaseAppProvider({
  children,
  fallback,
}: FirebaseAppProviderProps) {
  const [firebaseApp, setFirebaseApp] = useState<firebase.app.App | null>(null);

  useEffect(() => {
    if (process.env.REACT_APP_ENV == "production") {
      // console.log("loading Prod FB");
      setFirebaseApp(firebase.initializeApp(productionConfig));
    } else {
      // console.log("loading Stagig FB");
      setFirebaseApp(firebase.initializeApp(stagingConfig));

      // use firebase emulator during development
      //     if (
      //       process.env.NODE_ENV === "development" &&
      //       process.env.REACT_APP_USE_FIREBASE_EMULATOR === "yes"
      //     ) {
      //       firebase.firestore().settings({
      //         host: process.env.REACT_APP_FIRESTORE_HOST || "localhost:8080",
      //         ssl: false,
      //       });
      //       firebase
      //         .functions()
      //         .useFunctionsEmulator(`${window.location.origin}`);
      //     }
      //   });
    }
    firebase.analytics();
  }, []);

  return (
    <firebaseAppContext.Provider value={firebaseApp}>
      {!firebaseApp && fallback}
      {firebaseApp && children}
    </firebaseAppContext.Provider>
  );
}
