import { AxiosResponse } from "axios";
import { put, call, throttle } from "redux-saga/effects";
import {
  failedUserGivenDetails,
  failedDeleteLocation,
  successDeleteLocation,
  successUserGivenDetails,
  updateDeleteLocation,
  failedaddFavRestaurants,
  successaddFavRestaurants,
  userActions,
  editEmailRequest,
  editEmailSuccess,
  editEmailFailure,
  setPromoConsentSuccess,
  setPromoConsentFailure,
  getUserGivenDetails,
} from "./userActions";
import {
  getUserDetails,
  updateLocationDetails,
  addFavRestaurant,
  updateEmail,
  promoConsent,
} from "./userAPI";
import {
  USERGIVEN_DETAIL_REQUEST,
  LOCATION_DELETE_REQUEST,
  ADD_FAV_RESTAURANT,
  EDIT_EMAIL_REQUEST,
  SET_PROMO_CONSENT_REQUEST,
} from "./userConstants";
import {
  AddFavRestaurants,
  LocationDetails,
  UserGivenDetail,
} from "./userModels";

function* getUserGivenDetailsSaga(action: userActions) {
  try {
    const response: AxiosResponse = yield call(getUserDetails);
    if (response.status === 200) {
      yield put(successUserGivenDetails(response.data));
    }
  } catch (err) {
    yield put(failedUserGivenDetails());
  }
}

function* deleteUserLocation(action: userActions) {
  try {
    const response: AxiosResponse = yield call(
      updateLocationDetails,
      action.payload as LocationDetails
    );
    if (response.status === 200) {
      yield put(successDeleteLocation(response.data));
      yield put(getUserGivenDetails());
    }
  } catch (err) {
    yield put(failedDeleteLocation({ message: "Please Try Again" }));
  }
}

function* addFavRest(action: userActions) {
  try {
    const response: AxiosResponse = yield call(
      addFavRestaurant,
      action.payload as AddFavRestaurants
    );
    if (response.status === 200) {
      yield put(successaddFavRestaurants(response.data));
    }
  } catch (err) {
    yield put(failedaddFavRestaurants({ message: "Please Try Again" }));
  }
}

function* editEmail(action: userActions) {
  try {
    const response: AxiosResponse = yield call(
      updateEmail,
      action.payload as string
    );
    if (response.status === 200) {
      yield put(editEmailSuccess(response.data));
    }
  } catch (err) {
    yield put(editEmailFailure("Please Try Again"));
  }
}

// FOr Promotional Messages

function* setPromoConsentSaga(action: userActions) {
  try {
    const response: AxiosResponse = yield call(
      promoConsent,
      action.payload as number
    );
    if (response.status === 200) {
      yield put(setPromoConsentSuccess());
    }
  } catch (err) {
    yield put(setPromoConsentFailure("Please Try Again"));
  }
}

export default function* userSaga() {
  yield throttle(3000, USERGIVEN_DETAIL_REQUEST, getUserGivenDetailsSaga);
  yield throttle(3000, ADD_FAV_RESTAURANT, addFavRest);
  yield throttle(3000, LOCATION_DELETE_REQUEST, deleteUserLocation);
  yield throttle(3000, EDIT_EMAIL_REQUEST, editEmail);
  yield throttle(3000, SET_PROMO_CONSENT_REQUEST, setPromoConsentSaga);
}
