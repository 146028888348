import { types } from "@babel/core";
import { Store } from "common/configureStore";
import { typedAction } from "../actionTypes";
import { CartItem, CartTotal, EditOrderRequest, GetOrderDetails } from "../cart/cartModels";
import {
  CLEAR_GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_DETAIL_FAILURE,
  GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_LATEST_ORDERS_ERROR,
  GET_LATEST_ORDERS_REQUEST,
  GET_LATEST_ORDERS_SUCCESS,
  SET_CURRENT_ORDER_TYPE,
  CLEAR_LATEST_ORDERS,
  GET_ACTIVE_ORDERS_ERROR,
  GET_ACTIVE_ORDERS_SUCCESS,
  GET_ACTIVE_ORDERS_REQUEST,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  CLEAR_UPDATE_ORDER,
  GET_KDS_ORDER_DETAIL_REQUEST,
  RESET_GET_ORDER_DETAIL,
  CLEAR_ORDER_DETAILS,
  CLEAR_CURRENT_ORDER_TYPE,
  UPDATE_TIP_REQUEST,
  UPDATE_TIP_FAILURE,
  UPDATE_TIP_SUCCESS,
  CLEAR_TIP_DETAIL,
  SAVE_ORDER_DETAILS,
  START_EMPTY_CART_ORDER,
} from "./orderConstants";
import {
  LatestOrder,
  Order,
  OrderDetail,
  Orders,
  OrderStatusUpdateRequest,
  UpdateOrderTip,
} from "./orderModels";

// Get ONE ORDER Detail
export function getOrderDetail(orderId: GetOrderDetails) {
  return typedAction(GET_ORDER_DETAIL_REQUEST, orderId);
}

export async function  getCurrentOrderDetail() {
  const currentOrderId =
   await Store.getState().order.currentOrderDetail?.orderId || null;
  return currentOrderId
    ? typedAction(GET_ORDER_DETAIL_REQUEST, currentOrderId)
    : null;
}

export function clearGetOrderDetailRequest() {
  return typedAction(CLEAR_GET_ORDER_DETAIL_REQUEST, "");
}

export function setCurrentOrderType(data: string) {
  return typedAction(SET_CURRENT_ORDER_TYPE, data);
}

export function clearOrderDetails() {
  return typedAction(CLEAR_ORDER_DETAILS, "");
}

export function failedGetOrderDetail() {
  // Replace any with Network Data Fail Data format
  return typedAction(GET_ORDER_DETAIL_FAILURE, "");
}

export function clearCurrentOrderType() {
  return typedAction(CLEAR_CURRENT_ORDER_TYPE, "");
}

export function successGetOrderDetail(orderDetail: OrderDetail) {
  return typedAction(GET_ORDER_DETAIL_SUCCESS, orderDetail);
}

//get latest orders for Past experience
export function requestgetLatestOrders(customerId: string) {
  return typedAction(GET_LATEST_ORDERS_REQUEST, customerId);
}

export function successgetLatestOrders(orderDetail: LatestOrder) {
  return typedAction(GET_LATEST_ORDERS_SUCCESS, orderDetail);
}

export function errorgetLatestOrders() {
  return typedAction(GET_LATEST_ORDERS_ERROR, "");
}

export function clearLatestOrders() {
  return typedAction(CLEAR_LATEST_ORDERS, "");
}

//get all orders for specific user
export function requestgetOrders(customerId: string) {
  return typedAction(GET_ORDERS_REQUEST, customerId);
}

export function errorgetOrders() {
  return typedAction(GET_ORDERS_ERROR, "");
}

export function successgetOrders(orders: Orders) {
  return typedAction(GET_ORDERS_SUCCESS, orders);
}

// For Floating Cart
export function getActiveOrderRequest(customerId: string) {
  return typedAction(GET_ACTIVE_ORDERS_REQUEST, customerId);
}

export function getActiveOrderSuccess(orderDetail: LatestOrder) {
  return typedAction(GET_ACTIVE_ORDERS_SUCCESS, orderDetail);
}

export function getActiveOrderFailed() {
  return typedAction(GET_ACTIVE_ORDERS_ERROR, "");
}

export function updateOrderDetail(details: EditOrderRequest) {
  return typedAction(UPDATE_ORDER_REQUEST, details);
}

export function failedUpdateOrder() {
  return typedAction(UPDATE_ORDER_FAILURE, "");
}

export function successUpdateOrder(orderDetails: OrderDetail) {
  return typedAction(UPDATE_ORDER_SUCCESS, orderDetails);
}

export function clearUpdateOrder() {
  return typedAction(CLEAR_UPDATE_ORDER, "");
}

export function updateOrderTipDetail(details: UpdateOrderTip) {
  return typedAction(UPDATE_TIP_REQUEST, details);
}

export function failedUpdateOrderTip() {
  return typedAction(UPDATE_TIP_FAILURE, "");
}

export function successUpdateOrderTip(orderDetails: any) {
  return typedAction(UPDATE_TIP_SUCCESS, orderDetails);
}

export function clearUpdateOrderTip() {
  return typedAction(CLEAR_TIP_DETAIL, "");
}

export function getKDSOrderDetails(orderId: string) {
  return typedAction(GET_KDS_ORDER_DETAIL_REQUEST, orderId);
}

export function clearGetOrderDetail() {
  return typedAction(RESET_GET_ORDER_DETAIL, "");
}

export function startEmptyCartOrder() {
  return typedAction(START_EMPTY_CART_ORDER, "");
}

export function savePlaceOrderDetails(data: {
  discountValue?: any;
  discountType?: 'PERCENT' | 'FLATFEE';
  totals?: CartTotal[];
  orderTotal?:string;
  orderItems?: CartItem[];
  tip?: any;
  tipType?: 'PERCENT' | 'FLATFEE'|'';
  deliveryFee?:any;
  RedeemedWalletAmount?:any;
  selectedTableName?:any;
  selectedSectionId?:any;
  destinationLat?:any;
  destinationLon?:any;
  sourceLat?:any;
  sourceLon?:any;
  orderTypeId?:any;
}) {
  return typedAction(SAVE_ORDER_DETAILS, {
    discountValue:data?.discountValue,
    discountType:data?.discountType,
    totals:data?.totals,
    orderTotal:data?.orderTotal,
    orderItems:data?.orderItems,
    tip:data?.tip,
    tipType:data?.tipType,
    deliveryFee:data?.deliveryFee,
    RedeemedWalletAmount:data?.RedeemedWalletAmount,
    selectedTableName:data?.selectedTableName,
    selectedSectionId:data?.selectedSectionId,
    destinationLat:data?.destinationLat,
    destinationLon:data?.destinationLon,
    sourceLat:data?.sourceLat,
    sourceLon:data?.sourceLon,
    orderTypeId:data?.orderTypeId
  });
}

export type OrderActions = ReturnType<
  | typeof getOrderDetail
  | typeof clearGetOrderDetailRequest
  | typeof failedGetOrderDetail
  | typeof successGetOrderDetail
  | typeof requestgetOrders
  | typeof errorgetOrders
  | typeof successgetOrders
  | typeof requestgetLatestOrders
  | typeof successgetLatestOrders
  | typeof errorgetLatestOrders
  | typeof setCurrentOrderType
  | typeof clearLatestOrders
  | typeof getActiveOrderRequest
  | typeof getActiveOrderSuccess
  | typeof getActiveOrderFailed
  | typeof updateOrderDetail
  | typeof successUpdateOrder
  | typeof failedUpdateOrder
  | typeof clearUpdateOrder
  | typeof getKDSOrderDetails
  | typeof clearGetOrderDetail
  | typeof clearOrderDetails
  | typeof clearCurrentOrderType
  | typeof updateOrderTipDetail
  | typeof failedUpdateOrderTip
  | typeof successUpdateOrderTip
  | typeof clearUpdateOrderTip
  | typeof saveOrderDetails
>;
