import { CartItemOption } from "common/features/cart/cartModels";
import {
  Customization,
  RestaurantMenuItem,
} from "common/features/restaurant/restaurantModels";

export interface Category {
  [key: string]: {
    quantity: number;
    subCategories: string[];
  };
}
export interface SubCategory {
  [key: string]: {
    quantity: number;
    image: string;
    imageType: string;
  };
}

export const getAvailableCatgorySubCategory = (
  restaurantMenuDetails: RestaurantMenuItem[]
) => {
  let categoryTypes: Category = {};
  let subCategoryTypes: SubCategory = {};

  if (restaurantMenuDetails) {
    restaurantMenuDetails
      ?.filter((menuItem: RestaurantMenuItem) => menuItem.display == 1) // Display Filter
      .map((menuItem: RestaurantMenuItem) => {
        // Load Category Map
        if (menuItem.category && menuItem.subCategory) {
          if (Object.keys(categoryTypes).includes(menuItem.category)) {
            // Update Number of Items in that Category
            categoryTypes[menuItem.category].quantity =
              categoryTypes[menuItem.category].quantity + 1;

            // Add Subcategory to Category
            if (
              !categoryTypes[menuItem.category].subCategories.includes(
                menuItem.subCategory
              )
            ) {
              categoryTypes[menuItem.category].subCategories.push(
                menuItem.subCategory
              );
            }
          } else {
            // Create New Category
            categoryTypes[menuItem.category] = {
              quantity: 1,
              subCategories: [menuItem.subCategory],
            };
          }
        } else if (menuItem.subCategory) {
          //Category Null - Add SubCategory as Category
          let OTHERS = "Others";
          if (Object.keys(categoryTypes).includes(menuItem.subCategory)) {
            // Increase Quantity
            categoryTypes[menuItem.subCategory].quantity =
              categoryTypes[menuItem.subCategory].quantity + 1;
          } else {
            // Create New Category
            categoryTypes[menuItem.subCategory] = {
              quantity: 1,
              subCategories: [],
            };
          }
        }

        // Load Subcategory Map
        if (
          Object.keys(subCategoryTypes).includes(menuItem.subCategory) &&
          menuItem.category
        ) {
          subCategoryTypes[menuItem.subCategory] = {
            image:
              menuItem.subCategoryImg && menuItem.subCategoryImg.length > 0
                ? menuItem.subCategoryImg
                : "",
            imageType:
              menuItem.subCategoryImgType &&
              menuItem.subCategoryImgType.length > 0
                ? menuItem.subCategoryImgType
                : "",
            quantity: subCategoryTypes[menuItem.subCategory].quantity + 1,
          };
        } else {
          if (menuItem.subCategory) {
            subCategoryTypes[menuItem.subCategory] = {
              image: menuItem.subCategoryImg || "",
              imageType: menuItem.subCategoryImgType || "",
              quantity: 1,
            };
          }
        }
      });
  }

  return { categoryTypes, subCategoryTypes };
};

export const getDecimalPlacedPriceString = (price: number | string) => {
  if (typeof price == "number") {
    return price.toFixed(2);
  } else {
    return parseFloat(price).toFixed(2);
  }
};

// export const getTwoDecimalValue =(value:any)=>{
//   return Math.trunc(Number(value) * 100)/100;
// }

export const getTwoDecimalValue =(value:any)=>{
  const fullValue = value* 100;  
  return parseFloat((Math.trunc(fullValue)/100)?.toString()).toFixed(2);
}

export const getTruncTwoDecimalValue =(value:any)=>{
  return parseFloat((Math.trunc(value*100)/100)?.toString()).toFixed(2);
}

export const getDecimalPlacedPrice = (price: number | string) => {
  const str = price.toString().includes('.') ?  price.toString().split('.')[0] + '.' + price.toString().split('.')[1].slice(0,2) : price;
  if (typeof price == "number") {
    return Number(str);
  } else {
    return Number(str);
  }
};

export const getCustomisationType = (customisation: Customization) => {
  if (customisation.minRequired == 0 && customisation.maxRequired == 0) {
    return "view";
  }
  if (customisation.minRequired == customisation.maxRequired) {
    return "single";
  }
  if (customisation.minRequired < customisation.maxRequired) {
    return "multiple";
  }
  return "view";
};

export const getDefaultCustomisations = (
  itemCustomisations: Customization[]
) => {
  let addedTypes: string[] = [];
  let defCustomisations: CartItemOption[] = [];
  itemCustomisations?.map((customisation: Customization) => {
    if (
      getCustomisationType(customisation) == "single" &&
      !addedTypes.includes(customisation.typeId)
    ) {
      defCustomisations.push({
        modifierOptionId: customisation.modifierOptionId,
        modifier: customisation.type,
        optionName: customisation.optionName,
        price: customisation.price + "",
        quantity: "1",
        typeId: ""
      });
      addedTypes.push(customisation.typeId);
    }
  });
  return defCustomisations;
};
