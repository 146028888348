import { combineReducers } from "redux";

import checkInReducer from "common/features/checkIn/checkInReducer";
import authReducer from "./features/auth/AuthReducer";
import restaurantReducer from "./features/restaurant/restaurantReducers";
import cartReducer from "./features/cart/cartReducer";
import paymentReducer from "./features/payment/PaymentReducer";
import orderReducer from "./features/order/orderReducer";
import userReducer from "./features/user/userReducers";
import supportReducer from "./features/support/SupportReducer";
import offerReducer from "./features/offers/offferReducer";
import deliveryReducer from "./features/delivery/DeliveryReducer";
import tinyURLReducer from "./features/tinyURL/tinyURLReducer";
import navigationReducer from "./features/navigation/navigationReducer";

const reducers = {
  checkIn: checkInReducer,
  auth: authReducer,
  restaurant: restaurantReducer,
  cart: cartReducer,
  payment: paymentReducer,
  order: orderReducer,
  user: userReducer,
  support: supportReducer,
  offer: offerReducer,
  delivery: deliveryReducer,
  tinyURL: tinyURLReducer,
  navigation: navigationReducer,
};

export const rootReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof rootReducer>;
