import API from "common/api";
import { supportRequestData } from "./SupportModels";

export function supportRequest(data: supportRequestData) {
  return API({
    method: "post",
    url: "/support/createSupportRequest",
    data: data,
  });
}
