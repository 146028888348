import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useHistory, useParams, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import MenuItemDiscriptionPrompt from "components/prompts/menuItemDiscription";
import { ReactComponent as Back } from "common/assets/svg/leftArrow.svg";
import { ReactComponent as Cancel } from "common/assets/svg/cancel.svg";
import UnCheckedOption from "common/assets/images/unChecked.png";
import CheckedOption from "common/assets/images/checked.png";
import UnSelectedOption from "common/assets/images/unSelected.png";
import SelectedOption from "common/assets/images/selected.png";
import { ReactComponent as LeftArrow } from "common/assets/svg/leftArrow.svg";
import {
  Customization,
  OrderType,
} from "common/features/restaurant/restaurantModels";
import ItemCountSpinner from "components/InputComponents/ItemCountSpinner";
import ConfirmationPrompt from "components/prompts/confirmationPrompt";

import {
  addCartItem,
  addCustomiseIndividually,
  decrementCartItem,
  finishCustomisation,
  hideItemMaxCountPrompt,
  incrementCartItem,
  loadSavedCustomisation,
  removeCartItem,
  removeCustomiseIndividually,
  saveItemCustomisation,
  showItemMaxCountPrompt,
  updateCartItem,
} from "common/features/cart/cartActions";
import { RootState } from "common/rootReducer";
import { CartItem, CartItemOption } from "common/features/cart/cartModels";
import PriceText from "components/priceText";
import VegDishIcon from "common/assets/images/vegDish.png";
import { MenuRouteState } from "pages/restaurantHome";
import {
  getMerchantMenuURL,
  getRestaurantMenuURLWithTableID,
  getMerchantHomeURL,
} from "common/utils/route-util";
import {
  getCustomisationType,
  getDecimalPlacedPriceString,
  getDefaultCustomisations,
} from "common/utils/menu-helper-utils";
import {
  requestCheckOffers,
  successCheckOffers,
} from "common/features/offers/offerActions";
import CountAlertPrompt from "components/prompts/countAlertPrompt";
interface CustomisationType {
  name: string;
  inputType: string;
  enable: boolean;
  id: string;
}

interface Istate {
  redirectFromOrder?: boolean;
  prevPath?: string;
  loadLastIndex?: boolean;
}

const PreviewItem = (props: any) => {
  const isMobile = window.innerWidth <= 500;
  const params: any = useParams();
  const customisingItemId = useSelector(
    (state: RootState) => state.cart.customisingItem
  );
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const itemId = isMobile ? params?.itemId : customisingItemId[0]?.itemId;
  const { restaurantSlug, menuType, tableId } = useParams<any>();
  const location = useLocation();
  const itemsIndividualContainerRef = useRef<HTMLDivElement | null>(null);
  const [itemCount, setItemCount] = useState(0);
  const [selectedCustomisation, setSelectedCustomisation] = useState("");
  const [isSafari, setisSafari] = useState(false);
  const restaurantDetails = useSelector(
    (state: RootState) => state.restaurant.currentRestaurantDetail
  );
  const restaurantCountry = useSelector(
    (state: RootState) => state.restaurant.currentRestaurantDetail.country
  );
  const [openDiscriptionPopup, setOpenDiscriptionPopup] = useState(false);

  const restaurantOrderTypes = useSelector(
    (state: RootState) => state.restaurant.currentRestaurantDetail.orderTypes
  );
  //console.log(restaurantOrderTypes,"check")
  const restaurantOrderTypeId = useSelector(
    (state: RootState) => state.restaurant.restaurantOrderTypeId
  );
  const itemMaxCountPromptVisible = useSelector(
    (state: RootState) => state.cart.itemMaxCountPromptVisible
  );
  const currentRestaurantDetail = useSelector(
    (state: RootState) => state.restaurant.currentRestaurantDetail
  );

  const dineInType = restaurantDetails.orderTypes?.filter(
    (d) => d.typeGroup === "D"
  )[0];

  const isContactlessDineInOrder = useMemo(() => {
    if (restaurantOrderTypes) {
      return (
        (currentRestaurantDetail.dining == 2 ||
          currentRestaurantDetail.dining == 3) &&
        restaurantOrderTypes.find(
          (orderType: OrderType) => orderType.typeGroup == "D"
        )?.id == restaurantOrderTypeId &&
        tableId &&
        tableId.length > 0
      );
    }
    return false;
  }, [restaurantOrderTypeId, restaurantOrderTypes, currentRestaurantDetail]);
  const isAddMenu = useMemo((): boolean => {
    return (
      restaurantDetails.orderTypes?.some((orderType) => {
        return (
          orderType.typeName == menuType &&
          (isContactlessDineInOrder ||
            orderType.typeGroup != "D" ||
            (orderType.typeGroup === "D" && tableId))
          // &&
          // orderType.typeGroup != "D"
        );
      }) || false
    );
  }, [restaurantDetails]);
  // Getting Item Details from Restaurant Menu API
  const itemDetails = useSelector(
    (state: RootState) => state.cart.currentMenuItem
  );

  // For checking the offer from the API
  const cartvalue = useSelector(
    (state: RootState) => state.offer.checkofferresult
  );

  const [customizationAlert, setcustomizationAlert] = useState(false);

  const UserId = localStorage.getItem("USER_ID");
  const customerId = useSelector((state: RootState) => state.auth.user);

  const locationID = useSelector(
    (state: RootState) => state.restaurant.currentRestaurantDetail.id
  );
  const cartMenuType = useSelector(
    (state: RootState) => state.cart.cartMenuType
  );
  function ItemUpdate(itemId: string, quantity: string) {
    // console.log(locationID,restaurantOrderTypes,"checking the ordertypess")

    if (UserId) {
      dispatch(
        requestCheckOffers({
          customerId: UserId,
          itemId: itemId,
          quantity: quantity,
          locationId: locationID,
          orderType: cartMenuType,
        })
      );
    }
  }

  useEffect(() => {
    if (itemDetails) {
      const value = itemDetails;
      //console.log(cartvalue, "cartvalue");
      if (
        value.itemId ===
        cartvalue.offers[0]?.offerAttributes.itemDetails.itemCode
      ) {
        let isDiscountApplied = false;
        let offerData = null;
        let price = Number(value.price);
        let totalDiscountPrice = 0;
        let totalItemCount = Number(cartvalue.quantity)
          ? Number(cartvalue.quantity)
          : 1;

        if (
          cartvalue.offers.length > 0 &&
          cartvalue.offers[0]?.offerAttributes.itemDetails.itemCode
        ) {
          let discountprice = "";

          if (cartvalue && cartvalue.offers[0].offerType === "PERCENT") {
            let percentcalculation =
              (cartvalue.offers[0].offerRate / 100) * (price * totalItemCount);
            //  console.log(percentcalculation);
            discountprice =
              value.itemBasedOffers[0].offerAttributes.itemDetails
                .scaleLevel === 0
                ? Number(cartvalue.offers[0].maxDiscount) < percentcalculation
                  ? String(cartvalue.offers[0].maxDiscount)
                  : String(percentcalculation)
                : String(percentcalculation);
            //  console.log(discountprice, "discountprice");
          } else if (cartvalue && cartvalue.offers[0].offerType === "FLATFEE") {
            discountprice = String(cartvalue.offers[0].maxDiscount);
          }

          if (price) {
            totalDiscountPrice =
              price * totalItemCount -
              (discountprice && Number(discountprice)
                ? Number(discountprice)
                : 0);
          }
          isDiscountApplied = true;
          offerData = cartvalue.offers[0];
        }

        dispatch(
          updateCartItem(
            value.itemId,
            String(
              getCartPrice(
                totalDiscountPrice ? totalDiscountPrice : price * totalItemCount
              )
            ),
            isDiscountApplied,
            undefined,
            totalItemCount,
            undefined,
            true,
            totalDiscountPrice,
            itemDetails.stockQuantity,
          itemDetails.alertQuantity,
          )
        );
      }
    }
  }, [cartvalue]);

  function getCartPrice(price: number) {
    // console.log(price, "checking the price in getcartPrice");
    setitemPricevalue(price);
    // setTotalItemPrice(price);
    if (restaurantCountry.length > 0) {
      if (restaurantCountry == "US") {
        return getDecimalPlacedPriceString(price);
      } else {
        return getDecimalPlacedPriceString(price);
      }
    } else {
      return price;
    }
  }

  const hasCustomization = useMemo(() => {
    return itemDetails
      ? itemDetails.customization && itemDetails.customization.length > 0
      : false;
  }, [itemDetails]);

  const isItemCustomizationAddable = useMemo(() => {
    return hasCustomization;
  }, [hasCustomization]);

  const cartMenuDefaultTax = useSelector(
    (state: RootState) => state.cart.cartMenuDefaultTax
  );

  const itemCustomisations = itemDetails?.customization;

  // Check if this Item is Previuosly has Saved Customisation
  const isItemCustomisationSaved = useSelector((state: RootState) =>
    state.cart.customisationSavedItems.includes(itemId)
  );

  // Get Cart Items from Pseudo Cart
  const cartItemsSaved = useSelector((state: RootState) =>
    state.cart.customisingItem.filter((item) => {
      return item.itemId == itemId;
    })
  );
  const individualCustomisingItemsCount = cartItemsSaved.length;

  const cartItems = useSelector((state: RootState) =>
    state.cart.cart.items.filter((item) => {
      return item.itemId == itemId;
    })
  );

  // Customised Items Count Capacity
  const customisingOptionDetails = useMemo(() => {
    const options: any = {};

    itemCustomisations?.map((customization) => {
      if (!Object.keys(options).includes(customization.type)) {
        options[customization.type] = {
          max: customization.maxRequired,
          min: customization.minRequired,
          isBase: customization.isBase,
        };
      }
    });

    return options;
  }, [itemCustomisations]);

  // Get Item Count from Pseudo Cart
  const totalItemCount = useSelector((state: RootState) =>
    state.cart.customisingItem
      .filter((item) => {
        return item.itemId == itemId;
      })
      .reduce((accumulator: number, currentValue: CartItem) => {
        return accumulator + parseInt(currentValue.quantity);
      }, 0)
  );
  const cartSavedItemCount = useSelector((state: RootState) =>
    state.cart.cart.items
      .filter((item) => {
        return item.itemId == itemId;
      })
      .reduce((accumulator: number, currentValue: CartItem) => {
        return accumulator + parseInt(currentValue.quantity);
      }, 0)
  );

  useEffect(() => {
    if (totalItemCount > 0) {
      setItemCount(totalItemCount);
    }
  }, [totalItemCount]);

  // Check if this Item is Customised Individually
  const customisingIndividualItem = useSelector(
    (state: RootState) => state.cart.customisingIndividualItem
  );
  const itemIndividualCustomizationEnabled =
    customisingIndividualItem.enabled &&
    customisingIndividualItem.itemId == itemId;

  const getIndividualCustomisedItemCount = useCallback(
    (itemIndex: number) => {
      if (itemIndex < cartItemsSaved.length) {
        return cartItemsSaved[itemIndex].quantity;
      } else {
        return 0;
      }
    },
    [cartItemsSaved]
  );

  const redirectFromOrder =
    (location.state as Istate)?.redirectFromOrder || false;

  const loadLastIndex = (location.state as Istate)?.loadLastIndex || false;

  const getMenuItemImageUrl = useCallback((imgId: string, ext: string) => {
    return process.env.REACT_APP_IMAGE_URL + "img" + "/" + imgId + "." + ext;
  }, []);

  const [individualItemPrice, setIndividualItemPrice] = useState<number>(0);
  const [totalItemPrice, setTotalItemPrice] = useState<number>(0);
  const [itemPricevalue, setitemPricevalue] = useState<number>(0);
  const [customizationInitialized, setCustomizationInitialized] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [isSticky, setSticky] = useState(false);
  const [specialNotes, setSpecialNotes] = useState<string[]>([]);

  const submitPrompt = (answer: boolean) => {
    if (answer) {
      // Remove Item from Pseudo and Original Cart
      dispatch(removeCartItem(itemId, -1));
      dispatch(removeCartItem(itemId, 0, false));
      dispatch(removeCustomiseIndividually(itemId));
    }
    setShowConfirmationModal(false);
    props.setOpenCustomisation(false);
  };

  const cutomizationSuccess = () => {
    setcustomizationAlert(false);
  };

  const showPrompt = (itemId: any) => {
    setShowConfirmationModal(true);
  };

  // Customisation Name and Input type
  const [customisationOptionTypes, setCustomisationOptionTypes] = useState<
    CustomisationType[]
  >([]);

  const [currentCustomisations, setCurrentCustomisations] = useState<
    CartItemOption[][]
  >([]);

  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);

  const getItemCustomisationPrice = useCallback(
    (index: number) => {
      let customisationPrice = 0;

      if (index <= currentCustomisations.length - 1) {
        currentCustomisations[index].map((customisation) => {
          customisationPrice =
            customisationPrice + parseFloat(customisation.price);
        });
      }
      return customisationPrice;
    },
    [
      currentCustomisations,
      currentItemIndex,
      itemIndividualCustomizationEnabled,
    ]
  );

  // (Item Price + Customisation Price) * Quantity
  const getCurrentItemIndividualPrice = useCallback(() => {
    let itemPrice = itemDetails ? +itemDetails.price : 0;

    return (
      (itemPrice + getItemCustomisationPrice(currentItemIndex)) *
      +cartItemsSaved[currentItemIndex]?.quantity
    );
  }, [
    itemDetails,
    getItemCustomisationPrice,
    currentItemIndex,
    cartItemsSaved,
  ]);

  useEffect(() => {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        // Chrome
      } else {
        setisSafari(true); // Safari
      }
    }
  }, []);

  useEffect(() => {
    if (customisationOptionTypes.length > 0) {
      let filteredCustomisationTypes = customisationOptionTypes.filter(
        customisationTypeEnabledFilter
      );

      setSelectedCustomisation(filteredCustomisationTypes[0].name);
    }
  }, [customisationOptionTypes]);

  // Update Prices of Item
  useEffect(() => {
    if (itemDetails) {
      // Update Selected Items Price
      setIndividualItemPrice(getCurrentItemIndividualPrice());

      // Update Total Item Price
      if (itemIndividualCustomizationEnabled) {
        // Total Price of Item of Individual Customisation
        let totalPrice = 0;
        for (let i = 0; i < individualCustomisingItemsCount; i++) {
          let itemPrice =
            (getItemCustomisationPrice(i) + +itemDetails?.price) *
            +cartItemsSaved[i]?.quantity;

          totalPrice = totalPrice + itemPrice;
        }

        setTotalItemPrice(totalPrice);
      } else {
        //If suppose the added item has offers

        if (itemDetails.itemBasedOffers !== null) {
          if (cartvalue.offers.length !== 0) {
            setTotalItemPrice(
              getItemCustomisationPrice(0) * totalItemCount + +itemPricevalue
              //  (getItemCustomisationPrice(0) + +itemDetails?.price) * totalItemCount
            );
          } else {
            setTotalItemPrice(
              (getItemCustomisationPrice(0) + +itemDetails?.price) *
                totalItemCount
            );
          }
          setTotalItemPrice(
            getItemCustomisationPrice(0) * totalItemCount + +itemPricevalue
            //  (getItemCustomisationPrice(0) + +itemDetails?.price) * totalItemCount
          );
        } else {
          // Total Price of Item of same Customisation
          setTotalItemPrice(
            (getItemCustomisationPrice(0) + +itemDetails?.price) *
              totalItemCount
          );
        }
      }
    }
  }, [
    currentItemIndex,
    currentCustomisations,
    itemIndividualCustomizationEnabled,
    totalItemCount,
    individualCustomisingItemsCount,
    itemPricevalue,
    cartvalue,
    itemDetails,
  ]);

  useEffect(() => {
    // Add default Customization Options on Item Count Increase in Individual Customisation
    if (
      itemIndividualCustomizationEnabled &&
      individualCustomisingItemsCount > currentCustomisations.length &&
      customizationInitialized
    ) {
      for (
        let i = 0;
        i < individualCustomisingItemsCount - currentCustomisations.length;
        i++
      ) {
        const defCustomisations = getDefaultCustomisations(
          itemCustomisations || []
        );
        currentCustomisations.push(defCustomisations);
      }

      setCurrentCustomisations([...currentCustomisations]);

      // Update Special Note
      if (itemIndividualCustomizationEnabled) {
        if (specialNotes.length < individualCustomisingItemsCount) {
          specialNotes.push("");
          setSpecialNotes([...specialNotes]);
        }
      } else {
        setSpecialNotes([cartItemsSaved[0].comment]);
      }
    }
  }, [
    individualCustomisingItemsCount,
    itemIndividualCustomizationEnabled,
    currentCustomisations,
  ]);

  // Update Curretn Item Index, Prevent Index Exception
  useEffect(() => {
    if (currentItemIndex > currentCustomisations.length - 1) {
      setCurrentItemIndex(0);
    }
  }, [currentCustomisations]);

  const isCartItemType = (
    cartItem: CartItemOption,
    customisationTypeModifierOptionIds: string[]
  ) => {
    return customisationTypeModifierOptionIds.includes(
      cartItem.modifierOptionId
    );
  };

  const isContainsCustomisationType = (
    cartItemOptions: CartItemOption[],
    typeId: string,
    customisationTypeModifierOptionIds: string[]
  ) => {
    return (
      cartItemOptions.filter((cartItemOption) =>
        isCartItemType(cartItemOption, customisationTypeModifierOptionIds)
      ).length > 0
    );
  };

  const toggleCustomisationSingle = (
    customisationSelected: Customization,
    customisationTypeSelected: CustomisationType
  ) => {
    let singleCustomisationsIndex = 0;

    let customisationTypeModifierOptionIds =
      itemCustomisations
        ?.filter((type) => type.typeId === customisationTypeSelected.id)
        .map((type) => type.modifierOptionId) || [];

    if (currentCustomisations[currentItemIndex]) {
      if (
        isContainsCustomisationType(
          currentCustomisations[currentItemIndex],
          customisationTypeSelected.id,
          customisationTypeModifierOptionIds
        )
      ) {
        // If Customisation Present - Replace It
        currentCustomisations[currentItemIndex] = currentCustomisations[
          currentItemIndex
        ]?.map((customisation: CartItemOption) => {
          if (
            isCartItemType(customisation, customisationTypeModifierOptionIds)
          ) {
            // Modify Default customisation
            customisation.modifier = customisationSelected.type;
            customisation.optionName = customisationSelected.optionName;
            customisation.modifierOptionId =
              customisationSelected.modifierOptionId;
            customisation.price = customisationSelected.price.toString();
            customisation.quantity = "1";
          }
          return customisation;
        });
      } else {
        // Add new Customisation
        let currentItemCustomisations = currentCustomisations[currentItemIndex];
        currentItemCustomisations.push({
          modifierOptionId: customisationSelected.modifierOptionId,
          modifier: customisationSelected.type,
          optionName: customisationSelected.optionName,
          quantity: "1",
          price: customisationSelected.price.toString(),
        });
        currentCustomisations[currentItemIndex] = currentItemCustomisations;
      }
    } else {
      if (currentCustomisations.length == 0) {
        currentCustomisations.push([
          {
            modifierOptionId: customisationSelected.modifierOptionId,
            modifier: customisationSelected.type,
            optionName: customisationSelected.optionName,
            price: customisationSelected.price + "",
            quantity: "1",
          },
        ]);
        singleCustomisationsIndex = singleCustomisationsIndex + 1;
      } else if (currentCustomisations.length > 0) {
        currentCustomisations[0][singleCustomisationsIndex] = {
          modifierOptionId: customisationSelected.modifierOptionId,
          modifier: customisationSelected.type,
          optionName: customisationSelected.optionName,
          price: customisationSelected.price + "",
          quantity: "1",
        };
      }
    }

    setCurrentCustomisations([...currentCustomisations]);
  };

  const isModiferLimitReached = (modifier: string) => {
    if (
      currentCustomisations.length > 0 &&
      currentCustomisations[currentItemIndex]
    ) {
      const modifierCount = currentCustomisations[currentItemIndex].filter(
        (customisations) => customisations.modifier == modifier
      ).length;

      if (modifierCount < customisingOptionDetails[modifier].max) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const toggleCustomisationMultiple = (
    modifierId: string,
    modifer: string,
    value: string,
    price: string
  ) => {
    if (isSelectedOption(modifierId, value)) {
      currentCustomisations[currentItemIndex] = currentCustomisations[
        currentItemIndex
      ].filter((customisation) => {
        return !(
          customisation.modifierOptionId == modifierId &&
          customisation.optionName == value
        );
      });
    } else {
      if (currentCustomisations.length > 0) {
        !isModiferLimitReached(modifer) &&
          currentCustomisations[currentItemIndex].push({
            modifierOptionId: modifierId,
            modifier: modifer,
            optionName: value,
            price: price,
            quantity: "1",
          });
      } else {
        currentCustomisations.push([
          {
            modifierOptionId: modifierId,
            modifier: modifer,
            optionName: value,
            price: price,
            quantity: "1",
          },
        ]);
      }
    }

    setCurrentCustomisations([...currentCustomisations]);
  };

  useEffect(() => {
    if (redirectFromOrder) {
      // Add New Item
      addThisItemToCart(1, false);
      // Set Current Item
      setCurrentItemIndex(individualCustomisingItemsCount);
    }
    if (!itemDetails) {
      goToRestauratMenu(true);
    }
  }, []);

  useEffect(() => {
    // If Adding New Item Automatically, scroll to highlighted Item
    if (itemsIndividualContainerRef.current && redirectFromOrder) {
      // itemsIndividualContainerRef.current.scrollBy(
      //   itemsIndividualContainerRef.current.scrollWidth,
      //   0
      // );
      itemsIndividualContainerRef.current.scrollLeft =
        itemsIndividualContainerRef.current.scrollWidth;
    }
  }, [itemsIndividualContainerRef]);

  const goToRestauratMenu = useCallback((load: boolean) => {
    // load &&
    if (load && !isContactlessDineInOrder) {
      history.push(getMerchantMenuURL(restaurantSlug, menuType));
    } else if (!load && !isContactlessDineInOrder) {
      if (tableId) {
        dineInType &&
          history.push({
            pathname: getRestaurantMenuURLWithTableID(
              "restaurant",
              restaurantSlug,
              tableId,
              dineInType.typeName
            ),
            state: {
              loadMenuRequired: false,
              id: itemId,
            } as MenuRouteState,
          });
      } else {
        // console.log("Inside Else of ROuting  :::::");
        if (isMobile) {
          history.push({
            pathname: getMerchantMenuURL(restaurantSlug, menuType),
            state: { loadMenuRequired: false } as MenuRouteState,
          });
        } else {
          history.push({
            pathname: getMerchantHomeURL("restaurant", restaurantSlug),
            state: { loadMenuRequired: false } as MenuRouteState,
          });
        }
      }
    } else if (isContactlessDineInOrder) {
      history.push({
        pathname: getRestaurantMenuURLWithTableID(
          "restaurant",
          restaurantSlug,
          tableId,
          menuType
        ),
        state: { loadMenuRequired: false, id: itemId } as MenuRouteState,
      });
    }
  }, []);

  useEffect(() => {
    if (itemDetails && totalItemCount == 0 && isMobile) {
      // console.log("No Items");
      goToRestauratMenu(false);
    }
  }, [totalItemCount, itemDetails]);

  useEffect(() => {
    // Add Default Options for Single Input Types (Radio) and Customisation Types
    let singleCustomisationsIndex = 0;
    itemCustomisations?.map((customisation: Customization, i: number) => {
      if (
        customisationOptionTypes.filter(
          (customisationOptionType: CustomisationType) => {
            return customisationOptionType.name == customisation.type;
          }
        ).length == 0
      ) {
        // Selecte Default customisation Category
        if (i == 0 && selectedCustomisation == "") {
          setSelectedCustomisation(customisation.type);
        }

        // Load Customisation Types
        customisationOptionTypes.push({
          id: customisation.typeId,
          name: customisation.type,
          inputType: getCustomisationType(customisation),
          enable: customisation.typeAvailable == 1,
        });

        if (!isItemCustomisationSaved) {
          // Load Default Single Values
          // if (getCustomisationType(customisation) == "single") {
          //   // 0 - Select First Item - Single Item Customisation
          //   if (props.currentCustomisations.length == 0) {
          //     console.log("Herreeee:::777");
          //     console.log("Customisation At 781 :::", customisation);
          //     props.currentCustomisations.push([
          //       {
          //         modifierOptionId: customisation.modifierOptionId,
          //         modifier: customisation.type,
          //         optionName: customisation.optionName,
          //         price: customisation.price + "",
          //         quantity: "1",
          //       },
          //     ]);
          //     singleCustomisationsIndex = singleCustomisationsIndex + 1;
          //   } else if (props.currentCustomisations.length > 0) {
          //     console.log("Customisation at 795::", customisation);
          //     props.currentCustomisations[0][singleCustomisationsIndex] = {
          //       modifierOptionId: customisation.modifierOptionId,
          //       modifier: customisation.type,
          //       optionName: customisation.optionName,
          //       price: customisation.price + "",
          //       quantity: "1",
          //     };
          //   }
          // }
        }
      }
    });

    // Save Customisation Types
    // setCustomisationOptionTypes([...customisationOptionTypes]);

    if (!isItemCustomisationSaved) {
      // ## Save Initial Custimisation

      setCurrentCustomisations([...currentCustomisations]);
    } else {
      // ## Load Saved Customisations from Redux
      loadCustomisationData(true);
    }
    setCustomizationInitialized(true);
  }, [itemCustomisations]);

  const customisationSorted = [...customisationOptionTypes].sort((a, b) =>
    a.inputType > b.inputType ? -1 : 1
  );

  useEffect(() => {
    const activeCustomization = customisationSorted
      .filter(customisationTypeEnabledFilter)
      .map((customisationType: CustomisationType, index) => {
        return customisationType;
      });
    if (activeCustomization.length > 0) {
      setSelectedCustomisation(activeCustomization[0]?.name);
    }
  }, [customisationSorted.length]);

  // useEffect(() => {
  //   loadCustomisationData();
  // }, [cartItemsSaved]);

  const getCustomisations = useCallback(
    (items: CartItem[]): CartItemOption[][] => {
      let customisations: CartItemOption[][] = [];
      items.map((cartItem) => {
        let cartItemOptions: CartItemOption[] = [];
        cartItem.options.map((option) => {
          cartItemOptions.push({
            modifierOptionId: option.modifierOptionId,
            modifier: option.modifier,
            optionName: option.optionName,
            price: option.price,
            quantity: option.quantity,
          });
        });
        customisations.push(cartItemOptions);
      });
      return customisations;
    },
    []
  );

  const loadCustomisationData = useCallback(
    (pseudo: boolean) => {
      // ## Load Saved Customisations from Redux

      if (pseudo) {
        setCurrentCustomisations([...getCustomisations(cartItemsSaved)]);
      } else {
        setCurrentCustomisations([...getCustomisations(cartItems)]);
      }

      //Load Special Note
      let notes: string[] = [];
      cartItemsSaved.map((cartItemSaved) => {
        notes.push(cartItemSaved.comment);
      });
      setSpecialNotes([...notes]);
    },
    [cartItemsSaved, currentCustomisations]
  );

  const isSelectedOption = useCallback(
    (modifierId: string, value: string) => {
      if (currentItemIndex <= currentCustomisations.length - 1) {
        return (
          currentCustomisations[currentItemIndex].filter(
            (customisationValue) => {
              return (
                customisationValue.modifierOptionId == modifierId &&
                customisationValue.optionName == value
              );
            }
          ).length > 0
        );
      } else {
        return false;
      }
    },
    [currentCustomisations, currentItemIndex]
  );

  const isSelectedOptionSingle = useCallback(
    (modifierId: string) => {
      if (currentCustomisations.length !== 0) {
        return (
          currentCustomisations[currentItemIndex]?.filter(
            (customisationValue) => {
              return customisationValue.modifierOptionId == modifierId;
            }
          ).length > 0
        );
      } else {
        return false;
      }
    },
    [currentCustomisations, currentItemIndex]
  );

  const cancelCustomisation = useCallback(() => {
    if (individualCustomisingItemsCount > 1 && currentItemIndex > 0) {
      currentCustomisations.splice(currentItemIndex, 1);
      setCurrentCustomisations([...currentCustomisations]);
      dispatch(removeCartItem(itemId, currentItemIndex, true));
      // dispatch(removeLastItemInstance(itemId));
    } else if (individualCustomisingItemsCount == 1) {
      // Remove Last Item from Cart and move to custimize individually
      // dispatch(removeCartItem(itemId, -1, true));
      // dispatch(removeCustomiseIndividually(itemId));
      showPrompt(itemId);
    }
  }, [individualCustomisingItemsCount, currentItemIndex]);

  const getItemTax = useCallback(() => {
    return itemDetails && itemDetails.isDefaultItemTax && cartMenuDefaultTax
      ? cartMenuDefaultTax
      : itemDetails?.tax || [];
  }, [itemDetails, cartMenuDefaultTax]);

  const addThisItemToCart = useCallback(
    (quantity: number, withDetails: boolean) => {
      itemDetails &&
        dispatch(
          addCartItem(
            {
              itemId: itemId,
              itemName: itemDetails.itemName || "",
              itemAltName: itemDetails.itemAltName
                ? itemDetails.itemAltName
                : "",
              price: itemDetails.price || "",
              quantity: quantity + "",
              subTotal: "0",
              comment:
                withDetails && specialNotes.length > 0 ? specialNotes[0] : "",
              options:
                withDetails && currentCustomisations.length > 0
                  ? currentCustomisations[0]
                  : [],
              tax: getItemTax(),
              availability: itemDetails.availability,
              is_discount_applied: false,
              offer_amount: totalItemPrice,
              offer_data: null,
            },
            true
          )
        );
    },
    [itemDetails, itemId, cartMenuDefaultTax, itemPricevalue]
  );

  // Returns if a Customisation is enabled or not
  const customisationTypeEnabledFilter = useCallback(
    (customisationType: CustomisationType) => {
      return customisationType.enable;
    },
    []
  );

  const saveCustomization = () => {
    // if individual cust remove single onw with quntity and add multiple with quant 1 and cust given
    // else
    // add cust to item
    // Move Back to Menu Page
    //  history.goBack();
    // const;

    const isMinCustomisationError = (() => {
      let isMinError = false;
      // All Customisations for this Item
      const customisingOptions = Object.keys(customisingOptionDetails);

      customisingOptions.map((customisingOption) => {
        // Current Customisation
        if (currentCustomisations.length == 0) {
          if (customisingOptionDetails[customisingOption].min > 0)
            isMinError = true;
        }
        currentCustomisations.map((currentCustomisation) => {
          // Current Item
          const customisationCount = currentCustomisation.filter(
            (customisation) => customisation.modifier == customisingOption
          ).length;
          if (
            customisationCount < customisingOptionDetails[customisingOption].min
          ) {
            isMinError = true;
          }
        });
      });

      return isMinError;
    })();

    if (isMinCustomisationError) {
      setcustomizationAlert(true);
    } else {
      if (itemIndividualCustomizationEnabled) {
        for (let i = 0; i < currentCustomisations.length; i++) {
          itemDetails &&
            dispatch(
              saveItemCustomisation({
                customisation: [...currentCustomisations[i]],
                instanceIndex: i,
                itemId: itemId,
                price: itemDetails.price,
                specialNote: specialNotes[i],
                subTotal: getDecimalPlacedPriceString(
                  (getItemCustomisationPrice(i) +
                    parseFloat(itemDetails.price)) *
                    +getIndividualCustomisedItemCount(i)
                ),
                offer_amount: String(totalItemPrice),
                availability: itemDetails.availability,
                stockQuantity: itemDetails.stockQuantity,
                alertQuantity:itemDetails.alertQuantity,
              })
            );
        }
      } else {
        // Non Individual Customization Enabled
        itemDetails &&
          dispatch(
            saveItemCustomisation({
              customisation:
                currentCustomisations.length > 0
                  ? currentCustomisations[0].map((a) => Object.assign({}, a))
                  : [],
              instanceIndex: 0,
              itemId: itemId,
              price: itemDetails.price,
              specialNote: specialNotes[0],
              // subTotal: getDecimalPlacedPriceString(
              //   (getItemCustomisationPrice(0) + parseFloat(itemDetails.price)) *
              //     totalItemCount
              // ),
              subTotal:
                itemPricevalue !== 0
                  ? String(itemPricevalue)
                  : getDecimalPlacedPriceString(
                      (getItemCustomisationPrice(0) +
                        parseFloat(itemDetails.price)) *
                        totalItemCount
                    ),
              offer_amount: String(itemPricevalue),
              availability: itemDetails.availability,
                stockQuantity: itemDetails.stockQuantity,
                alertQuantity:itemDetails.alertQuantity,
            })
          );
      }
      dispatch(finishCustomisation(itemId));
      isMobile ? routeBack() : props.onClose(true);
    }
  };

  function routeBack() {
    history.goBack();

    // if ((location.state as Istate).prevPath) {

    //   const prevPath = (location.state as Istate).prevPath || "/";
    //   history.push(prevPath, { loadMenuRequired: false } as MenuRouteState);
    // } else {
    //   history.goBack();
    // }
  }

  const getBasePricedValue = (price: number) => {
    return itemDetails ? itemDetails?.price + price : "";
  };

  // const orderTypeTabRef = useRef<HTMLDivElement>(null);
  const restaurantListRef = useRef<HTMLDivElement>(null);
  // const handleScroll = () => {
  //   if (restaurantListRef.current != null && orderTypeTabRef.current != null) {
  //     const tabBoundings = orderTypeTabRef.current.getBoundingClientRect();
  //     const listBoundings = restaurantListRef?.current?.getBoundingClientRect();

  //     const isTop = listBoundings.top - tabBoundings.height;
  //     if (isTop <= 0) {
  //       setSticky(true);
  //       restaurantListRef.current.style.marginTop =
  //         tabBoundings.height.toString() + "px";
  //     } else {
  //       setSticky(false);
  //       restaurantListRef.current.style.marginTop = "0px";
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", () => handleScroll);
  //   };
  // }, []);

  return (
    <Fragment>
      <div className="cdp-customize">
        <MenuItemDiscriptionPrompt isOpen={openDiscriptionPopup} />
        <ConfirmationPrompt
          question={
            " Are you sure you want to remove all the customization that you’ve added "
          }
          isOpen={showConfirmationModal}
          submitPrompt={submitPrompt}
          submitText={"REMOVE"}
          cancelText={"CANCEL"}
          InfoImage={null}
          type="prompt"
        />
        <ConfirmationPrompt
          question={"Please Contact Restaurant for Bulk Orders"}
          isOpen={itemMaxCountPromptVisible}
          submitPrompt={(answer: boolean) => dispatch(hideItemMaxCountPrompt())}
          submitText={"ok"}
          cancelText={"Cancel"}
          InfoImage={null}
          type="prompt"
        />
        <ConfirmationPrompt
          question={"Minimum 1 customization required !!"}
          isOpen={customizationAlert}
          submitPrompt={cutomizationSuccess}
          InfoImage={null}
          type={"alert"}
          submitText="Ok"
        />
        <div
          // className="custom-header"
          className={isSticky ? "custom-header" : "custom-header"}
          // ref={orderTypeTabRef}
        >
          {isMobile && (
            <div className="left-arrow-customization">
              <LeftArrow onClick={() => history.goBack()} />
            </div>
          )}
          {itemDetails?.itemImage ? (
            <img
              id="custom-image"
              src={getMenuItemImageUrl(
                itemDetails?.itemImage || "",
                itemDetails?.itemType?.split("/")[1] || ""
              )}
              alt="Loading"
            />
          ) : (
            <img
              id="custom-image"
              className="dishPlaceholder-custom"
              src={VegDishIcon}
            />
          )}

          <span id="custom-heading">{itemDetails?.itemName}</span>
          <span id="custom-heading-itemaltname">
            {itemDetails?.itemAltName}
          </span>
          <div id="ingredient-container">
            <span id="food-content">
              {itemDetails?.ingredient?.map((ingredient) => {
                return (
                  <span className="food-content-container">
                    {ingredient.type && ingredient.img && (
                      <span className="food-content-image-container">
                        <img
                          style={{
                            borderRadius: "50%",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={getMenuItemImageUrl(
                            ingredient.img,
                            ingredient.type.split("/")[1]
                          )}
                          alt="Loading"
                        />
                      </span>
                    )}
                    {ingredient.name}
                  </span>
                );
              })}
            </span>
          </div>

          
            <span className="customisation-menu-detail-price">
              Base Price :
              <p className="money-symboll">
                {" "}
                {restaurantCountry == "US" ? (
                  <Fragment>&#36;</Fragment>
                ) : (
                  <Fragment> &#x20B9;</Fragment>
                )}{" "}
              </p>
              {itemDetails && itemDetails?.price}
            </span>
          

          <div
            style={{
              padding: "5px",
              display: `${
                itemDetails?.stockQuantity == itemCount ? "block" : "none"
              }`,
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "Red",
              }}
            >
              Out of stock
            </p>
          </div>
          {itemDetails &&
            itemDetails.description?.split(".").map((desc) => {
              return <p className="desc">{desc}</p>;
            })}

          {itemCount > 0 ? (
            <>
              {isItemCustomizationAddable ? (
                <Fragment>
                  <div className="cust-sub-head">
                    Customize your order below:
                  </div>
                </Fragment>
              ) : null}

              {itemIndividualCustomizationEnabled ? (
                <Fragment>
                  {/* Individual Item Selections */}
                  <div
                    className="custom-item-elem"
                    ref={itemsIndividualContainerRef}
                  >
                    {new Array(currentCustomisations.length)
                      .fill(0)
                      .map((number: any, i) => {
                        return (
                          <div
                            className={
                              currentItemIndex == i
                                ? "Item-count Item-count-selected"
                                : "Item-count"
                            }
                            key={i}
                            onClick={() => {
                              setCurrentItemIndex(i);
                            }}
                          >
                            <span>
                              Item {i + 1}{" "}
                              {" (" + getIndividualCustomisedItemCount(i) + ")"}
                            </span>
                            <Fragment>
                              {currentItemIndex == i ? (
                                <Cancel
                                  className="cancel-item"
                                  onClick={() => {
                                    cancelCustomisation();
                                  }}
                                />
                              ) : null}
                            </Fragment>
                          </div>
                        );
                      })}
                  </div>

                  <div className="item-indiv-price">
                    <span>Item {currentItemIndex + 1} Price</span>
                    <PriceText
                      price={getCurrentItemIndividualPrice().toFixed(2)}
                    />
                  </div>
                </Fragment>
              ) : null}

              <div
                ref={itemsIndividualContainerRef}
                className="subcategory-list-container"
              >
                {customisationSorted
                  .filter(customisationTypeEnabledFilter)
                  .map((customisationType: CustomisationType, index) => {
                    return (
                      <div
                        className={
                          selectedCustomisation == customisationType.name
                            ? "subcategory-container selected-subcategory-container"
                            : "subcategory-container"
                        }
                        onClick={() => {          
                          restaurantListRef.current?.children[
                            index
                          ].scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                          setSelectedCustomisation(customisationType.name);
                          setTimeout(() => {
                            const yOffset = -550;
                            const item = document.querySelector(
                              ".relocate-" +
                                customisationType.name
                                  .toLocaleUpperCase()
                                  .replace(/[^a-zA-Z]/g, "")
                            );

                            if (item) {
                              if (isSafari) {
                                item.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start",
                                });
                              } else {
                                const y =
                                  item.getBoundingClientRect().top +
                                  window.pageYOffset +
                                  yOffset;
                                window.scrollTo({ top: y, behavior: "smooth" });
                              }
                            }
                          }, 10);
                        }}
                      >
                        <span className="subcategory-text">
                          {customisationType.name}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : null}
        </div>

        {/* className={
                        !itemIndividualCustomizationEnabled
                          ? "cust-options-selcted"
                          : ""
                      } */}

        {itemCount > 0 ? (
          <Fragment>
            <div>
              <div className="edit-container" ref={restaurantListRef}>
                {customisationSorted
                  .filter(customisationTypeEnabledFilter)
                  .map((customisationType: CustomisationType, index) => {
                    return (
                      <div key={index}>                   
                          <div>
                            {itemCustomisations
                              ?.filter((customisation: Customization) => {
                                return (
                                  customisation.type == customisationType.name
                                );
                              })
                              .map((customisation: Customization) => {
                                return (
                                  <span
                                    key={customisation.optionName}
                                    className={"edit-value"}
                                  >
                                    <div>
                                      <span className="Custom-Label">
                                        {customisation.optionName}
                                      </span>
                                    </div>
                                    {/* <PriceText
                                    price={parseFloat(
                                      getBasePricedValue(customisation.price)
                                    ).toFixed(2)}
                                  /> */}

                                    {customisation.isBase === 1 ? (
                                      <PriceText
                                        isBase={false}
                                        price={parseFloat(
                                          getBasePricedValue(
                                            customisation.price
                                          )
                                        ).toFixed(2)}
                                      />
                                    ) : (
                                      <PriceText
                                        isBase={true}
                                        price={customisation.price.toFixed(2)}
                                      />
                                    )}
                                  </span>
                                );
                              })}
                          </div>
          

                        {customisationSorted.filter(
                          customisationTypeEnabledFilter
                        ).length -
                          1 !=
                        index ? (
                          <span className="cust-seperator"></span>
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            </div>
          </Fragment>
        ) : null}

      </div>
    </Fragment>
  );
};
export default PreviewItem;
