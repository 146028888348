import { typedAction } from "../actionTypes";
import {
  CLEAR_USE_OFFER,
  GET_OFFERS_ERROR,
  GET_OFFERS_REQUEST,
  GET_OFFERS_SUCCESS,
  OFFER_VALIDITY_ERROR,
  OFFER_VALIDITY_REQUEST,
  OFFER_VALIDITY_SUCCESS,
  USE_OFFER_ERROR,
  USE_OFFER_REQUEST,
  USE_OFFER_SUCCESS,
  SET_OFFER_DATA,
  CLEAR_OFFER_DATA,
  CLEAR_VALIDITY_DATA,
  CLEAR_OFFERS,
  AVAIL_WALLER_AMOUNT,
  GET_CHECK_OFFERS_REQUEST,
  GET_CHECK_OFFERS_SUCCESS,
  GET_CHECK_OFFERS_ERROR,
  CLEAR_CHECK_OFFERS,
  APPLIED_OFFERS_REQUEST,
  APPLIED_OFFERS_SUCCESS,
  APPLIED_OFFERS_ERROR,
  CLEAR_APPLIED_OFFERS,
} from "./offferConstants";
import {
  Offer,
  OfferValidityRequestData,
  UseOfferRequestData,
  offerRequestBody,
  QuantiyBasedOfferRequest,
  checkoffer,
  AppliedoffersRequest,
} from "./offferModels";

//get all offers
export function requestgetOffers(offerRequestBody: offerRequestBody) {
 // console.log(offerRequestBody,"checkofferrequestbody")
  return typedAction(GET_OFFERS_REQUEST, offerRequestBody);
}

export function errorgetOffers() {
  return typedAction(GET_OFFERS_ERROR, "");
}

export function successgetOffers(offers: Offer[]) {
  return typedAction(GET_OFFERS_SUCCESS, offers);
}
export function cleargetOffers() {
  return typedAction(CLEAR_OFFERS, "");
}

// Check Offer Validity
export function requestOfferVality(details: OfferValidityRequestData) {
  return typedAction(OFFER_VALIDITY_REQUEST, details);
}

export function errorOfferVality(data: string) {
  return typedAction(OFFER_VALIDITY_ERROR, data);
}

export function successOfferVality() {
  return typedAction(OFFER_VALIDITY_SUCCESS, "");
}

// Use Offer
export function requestUseOffer(details: UseOfferRequestData) {
  
  return typedAction(USE_OFFER_REQUEST, details);
}

export function errorUseOffer() {
  return typedAction(USE_OFFER_ERROR, "");
}

export function successUseOffer() {
  return typedAction(USE_OFFER_SUCCESS, "");
}

export function clearUseOffer() {
  return typedAction(CLEAR_USE_OFFER, "");
}

// Set Selected Offer

export function setOfferData(data: Offer) {


  return typedAction(SET_OFFER_DATA, data);
}

// CLear selected Offer

export function clearSelectedOffer() {
  return typedAction(CLEAR_OFFER_DATA, "");
}

// Clear Validity Data
export function clearOfferValidityData() {
  return typedAction(CLEAR_VALIDITY_DATA, "");
}

export function setAvailWalletAmount(data: boolean) {

  return typedAction(AVAIL_WALLER_AMOUNT, data);
}
// export function setRedeemedWalletAmount(data:string) {
//   return typedAction(REDEEMED_WALLER_AMOUNT,data)
// }

//Check the best offers and details
export function requestCheckOffers(offerRequestBody: QuantiyBasedOfferRequest) {
  //console.log(offerRequestBody,"offerRequestBody")
  return typedAction(GET_CHECK_OFFERS_REQUEST, offerRequestBody);
}

export function errorCheckOffers() {
  return typedAction(GET_CHECK_OFFERS_ERROR, "");
}

export function successCheckOffers(offers: checkoffer[],quantity:string) {
  return typedAction(GET_CHECK_OFFERS_SUCCESS, {offers,quantity});
}
export function clearCheckOffers() {
  return typedAction(CLEAR_CHECK_OFFERS, "");
}
// check the Applied Offers and get the OfferIds which is applied

export function requestAppliedOffers(AppliedOffersRequestBody:AppliedoffersRequest) {
 return typedAction(APPLIED_OFFERS_REQUEST, AppliedOffersRequestBody);
}

export function errorAppliedOffers(){
  return typedAction(APPLIED_OFFERS_ERROR,"");
}
export function successAppliedOffers(){
  return typedAction(APPLIED_OFFERS_SUCCESS,"");
}

export function clearAppliedoffers(){
  return typedAction(CLEAR_APPLIED_OFFERS,"");
}


export type OfferActions = ReturnType<
  | typeof requestgetOffers
  | typeof errorgetOffers
  | typeof successgetOffers
  | typeof requestOfferVality
  | typeof errorOfferVality
  | typeof successOfferVality
  | typeof requestUseOffer
  | typeof errorUseOffer
  | typeof successUseOffer
  | typeof clearUseOffer
  | typeof setOfferData
  | typeof clearSelectedOffer
  | typeof clearOfferValidityData
  | typeof cleargetOffers
  | typeof requestCheckOffers 
  | typeof errorCheckOffers 
  | typeof successCheckOffers 
  | typeof clearCheckOffers 
  | typeof requestAppliedOffers 
  | typeof errorAppliedOffers 
  | typeof successAppliedOffers 
  | typeof clearAppliedoffers 
  | typeof setAvailWalletAmount
  
  
>;
