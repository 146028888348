import API from "common/api";
import {
  CNPDataOrder,
  FirstDataOrder,
  offlineOrderData,
  RazorPayTransferOrder,
  StripeInitiatePaymentPayload,
} from "./PaymentModels";
import { encryptJson } from "common/utils/react-ec-utils";

export function razorPayTransferOrderAPI(details: RazorPayTransferOrder) {
  const data = encryptJson(details);
  return API({
    method: "post",
    url: "/payment/authorize",
    data: details,
    //params:{data}
  });
}
export function firstDataTransferOrderAPI(details: FirstDataOrder) {
  const data = encryptJson(details);
  return API({
    method: "post",
    url: "/payment/authorize-payment",
    params:{data}
  });
}

export function catpurePaymentAPI(details: any) {
  const data = encryptJson(details);
  return API({
    method: "post",
    url: "/payment/collect-payment",
    params:{data} 
  });
}

export function stripeInitatePaymentAPI(details: StripeInitiatePaymentPayload) {
  return API({
    method: "post",
    url: "/payment/initiatePayment",
    data: details,
  });
}

export function offlinePaymentAPI(details: offlineOrderData) {
  const data = encryptJson(details);
  return API({
    method: "post",
    url: "/payment/customer-initiate-offline-payment",
    params:{data} 
  });
}

export function initiatetCNPPayemnetAPI(details: CNPDataOrder,) {
    const data = encryptJson(details);
    return API({
    method: 'post',
    url: '/payment/start-transaction',
    params:{data},
  });
}
