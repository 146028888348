import React, {
  createContext,
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  ACCESS_TOKEN,
  GUEST_EMAIL,
  GUEST_MOBILE_NUMBER,
  GUEST_USER_NAME,
  REFRESH_TOKEN,
  USER_ID,
} from "common/constants";
import {
  saveGuestUser,
  storeUser,
  signOut,
} from "common/features/auth/AuthActions";
import { clearUserData } from "common/features/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "common/rootReducer";
import { UserDetails } from "common/features/auth/AuthModels";
import { getUserGivenDetails } from "common/features/user/userActions";
import { clearSelectedLocation } from "common/features/restaurant/restaurantAction";
import { clearPlaceOrder } from "common/features/cart/cartActions";
import { startEmptyCartOrder } from "common/features/order/orderActions";
interface AuthServiceProps {
  children: ReactNode;
  fallback?: ReactElement;
}

const userStates = ["guest", "guestSignedIn", "signedIn"] as const;

type userStateType = typeof userStates[number];

interface AuthContextType {
  userState: userStateType;
  userAuth: UserDetails | null;
  signOut: any;
  refreshUserData: any;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export default function AuthService({ children, fallback }: AuthServiceProps) {
  const dispatch = useDispatch();

  const isUserSignedIn = useSelector((state: RootState) => state.auth.signedIn);
  const User = useSelector((state: RootState) => state.auth.user);
  const GuestUser = useSelector((state: RootState) => state.auth.guestUser);
  const userID = localStorage.getItem("USER_ID");

  const refreshUserData = () => {
    userID && isUserSignedIn && dispatch(getUserGivenDetails());
  };

  // console.log({User,isUserSignedIn,GuestUser,userID});
  

  const userState = useMemo((): userStateType => {
    if (User?.id && User.accessToken && User.refreshToken) {
      dispatch(clearSelectedLocation());
      refreshUserData();
      return "signedIn";
    } else if (User?.id && GuestUser) {
      return "guestSignedIn";
    } else {
      return "guest";
    }
  }, [User, GuestUser]);

  const LogOut = useCallback(() => {
    // Clear Local Storage
    // localStorage.clear();
    signOut();
    clearUserData();
    clearPlaceOrder();
    startEmptyCartOrder();
    // Clear Redux Store
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem(USER_ID);

    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);

    const guestName = localStorage.getItem(GUEST_USER_NAME);
    const guestMobile = localStorage.getItem(GUEST_MOBILE_NUMBER);
    const guestEmail = localStorage.getItem(GUEST_EMAIL);

    if (userId && accessToken && refreshToken) {
      // Login Flow
      dispatch(
        storeUser({
          id: userId,
          accessToken: accessToken,
          refreshToken: refreshToken,
        })
      );
      refreshUserData();
    } else if (guestName && guestMobile && userId) {
      // Guest Flow
      dispatch(
        storeUser({
          id: userId,
        })
      );

      guestName &&
        guestMobile &&
        guestEmail &&
        dispatch(
          saveGuestUser({
            fullName: guestName,
            mobile: guestMobile,
            email: guestEmail,
            adultSize: 0,
            kidzSize: 0,
          })
        );
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userState: userState,
        userAuth: User,
        signOut: LogOut,
        refreshUserData: refreshUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
