import { produce } from "immer";
import {
  CREATE_SUPPORT_FAILURE,
  CREATE_SUPPORT_REQUEST,
  CREATE_SUPPORT_SUCCESS,
} from "./SupportConstants";
import { SupportActions } from "./SupportActions";
import { SupportState } from "./SupportModels";

const initialCheckInState: SupportState = {
  supportRequestLoading: false,
  supportRequestSuccess: false,
};
export default function supportReducer(
  state: SupportState = initialCheckInState,
  action: SupportActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case CREATE_SUPPORT_REQUEST:
        draft.supportRequestLoading = true;
        draft.supportRequestSuccess = false;
        break;
      case CREATE_SUPPORT_SUCCESS:
        draft.supportRequestLoading = false;
        draft.supportRequestSuccess = true;
        break;
      case CREATE_SUPPORT_FAILURE:
        draft.supportRequestLoading = false;
        draft.supportRequestSuccess = false;
    }
  });
}
